import { useAtom } from 'jotai';
import { toastMarginAtom, toastOpenAtom, toastStyleAtom, toastTextAtom  } from '../../context/atoms/Atoms.Storage';

export const useToast = () => {
    const [, setIsToastOpen] = useAtom(toastOpenAtom);
    const [, setToastText] = useAtom(toastTextAtom);
    const [, setToastStyle] = useAtom(toastStyleAtom);
    const [, setToastMargin] = useAtom(toastMarginAtom);
  
    const openCustomToast = (message, style, toastMargin) => {
      setIsToastOpen(true);
      setToastText(message);
      setToastStyle(style);
      setToastMargin(toastMargin);
    };
  
    return { openCustomToast };
  };