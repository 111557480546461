export function WsCloseIntervention(_message) {

    const socket = new WebSocket('ws://localhost:3001/service');
    socket.onopen = () => {
        
         socket.send(_message);
    };

    socket.onerror = (error) => {
        
         console.error('Error WebSocket:', error);
    };
};

