import { useAtom } from "jotai";
import { changedItemsAtom, reviewsAtom } from "../../../../context/atoms/Atoms.Storage";
import { PartsEntity } from "../../../../context/mappers/Vehicle.Mapper";
import EditStates from "../../../../context/EditStates";

export default function SetInterventionPartValue() {
  const { insertValueStateArray, updateObjectById } = EditStates();

  const [changedItemsState, setChangedItemsState] = useAtom(changedItemsAtom);
  const [reviewsState, setReviewsState] = useAtom(reviewsAtom);

  const setValues = (event, _isPiece, _data_values, _id, _name, _useStateName, _stateMap, _setStateMap) => {
    const Item = Object.assign({}, PartsEntity);

    Item.id = _id;
    Item.name = _name;
    Item.piece = _isPiece;
    Item.status = 0;
    Item.caducidadMeses = null;
    Item.caducidadKm = null;
    Item.isReplaced = false;

    if (_isPiece === true) {

      if (_data_values.value_months !== "###") {
        if (_data_values.value_months != 0) {
          Item.caducidadMeses = _data_values.value_months;
        }
      } else {
        if (_stateMap[_useStateName]) {
          Item.caducidadMeses = _stateMap[_useStateName].caducidadMeses;
        }
      }

      if (_data_values.value_kms !== "###") {
        if (_data_values.value_months != 0) {
          Item.caducidadKm = _data_values.value_kms;
        }
      } else {
        if (_stateMap[_useStateName]) {
          Item.caducidadKm = _stateMap[_useStateName].caducidadKm;
        }
      }
    } else if (_isPiece === false) {

      if (_data_values.status !== "###") {
        Item.status = Number(_data_values.status);
      }

    }

    if (_data_values.value_checkbox !== "###") {
      Item.isReplaced = _data_values.value_checkbox || false;
    } else {
      Item.isReplaced = _stateMap[_useStateName].isReplaced || false;
    }

    if (_data_values.value_checkbox !== "###") {
      Item.isReplaced = _data_values.value_checkbox || false;
    }



    if (_stateMap) {
      if (_isPiece === true) {
        const existingItem = changedItemsState.find((item) => item.id === _id);

        if (!existingItem) {
          insertValueStateArray(Item, setChangedItemsState);
        } else {
          let changedItems = updateObjectById(_id, Item, changedItemsState);
          setChangedItemsState(changedItems);
        }
      } else if (_isPiece === false) {
        const existingItem = reviewsState.find((item) => item.id === _id);

        if (!existingItem) {
          insertValueStateArray(Item, setReviewsState);
        } else {
          let changedItems = updateObjectById(_id, Item, reviewsState);
          setReviewsState(changedItems);
        }
      }
    }

    _setStateMap((prevState) => ({
      ...prevState,
      [_useStateName]: Object.assign({}, Item),
    }));

  };

  return { setValues };
}
