const NormalizeId = (str) =>
  str
    .normalize("NFD") // Normaliza a string para decompor os caracteres acentuados em seus equivalentes não acentuados
    .replace(/[\u0300-\u036f]/g, "") // Remove os diacríticos
    .replace(/[^a-zA-Z0-9\s_]/g, "") // Remove caracteres especiais exceto espaços
    .replace(/ /g, "_")
    .replace("_de_", "_")
    .replace(" y ", "_")
    .replace(" Y ", "_")
    .toLowerCase()
    .trim(); // Converte a string para minúsculas

const CapitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export { NormalizeId, CapitalizeFirstLetter };
