export default function InitChangedItems_State() {
  const PiezasOptionsFieldsArray = [
    {
      id: "aceite_motor",
      label: "Aceite Motor",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "aceite_motor",
          label: "Caducidad en",
          img: "ico_aceite_motor",
          defaultValues: { caducidadMeses: 12, caducidadKm: 20000 , isReplaced : false },
        },
      ],
    },
    {
      id: "neumaticos_delanteros",
      label: "Neumáticos Delanteros",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "neumaticos_delanteros",
          label: "Caducidad en",
          img: "ico_neumaticos",
          defaultValues: { caducidadMeses: 80, caducidadKm: 40000, isReplaced : false },
        },
      ],
    },
    {
      id: "neumaticos_traseros",
      label: "Neumáticos Traseros",
      piece: true,

      variant: "normal",
      fields: [
        {
          id: "neumaticos_traseros",
          label: "Caducidad en",
          img: "ico_neumaticos",
          defaultValues: { caducidadMeses: 80, caducidadKm: 60000, isReplaced : false },
        },
      ],
    },
    {
      id: "filtro_aire",
      label: "Filtro Aire",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "filtro_aire",
          label: "Caducidad en",
          img: "ico_filtro_aire",
          defaultValues: { caducidadMeses: 12, caducidadKm: 20000, isReplaced : false },
        },
      ],
    },
    {
      id: "filtro_combustible",
      label: "Filtro Combustible",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "filtro_combustible",
          label: "Caducidad en",
          img: "ico_filtro_combustible",
          defaultValues: { caducidadMeses: 24, caducidadKm: 60000, isReplaced : false },
        },
      ],
    },
    {
      id: "filtro_habitaculo",
      label: "Filtro Habitáculo",
      piece: true,

      variant: "normal",
      fields: [
        {
          id: "filtro_habitaculo",
          label: "Caducidad en",
          img: "ico_filtro_habitaculo",
          defaultValues: { caducidadMeses: 12, caducidadKm: 12000, isReplaced : false },
        },
      ],
    },
    {
      id: "filtro_aceite",
      label: "Filtro Aceite",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "filtro_aceite",
          label: "Caducidad en",
          img: "ico_filtro_aceite",
          defaultValues: { caducidadMeses: 12, caducidadKm: 20000, isReplaced : false },
        },
      ],
    },
    {
      id: "correa_distribucion",
      label: "Correa Distribución",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "correa_distribucion",
          label: "Caducidad en",
          img: "ico_correa",
          defaultValues: { caducidadMeses: 60, caducidadKm: 90000, isReplaced : false },
        },
      ],
    },

    {
      id: "correa_accesorios",
      label: "Correa Accesorios",
      piece: true,
      variant: "normal",
      fields: [
        {
          id: "correa_accesorios",
          label: "Caducidad en",
          img: "ico_correa",
          defaultValues: { caducidadMeses: 60, caducidadKm: 90000, isReplaced : false },
        },
      ],
    },
    {
      id: "pastillas_freno_delanteras",
      label: "Pastillas Freno Delanteras",
      piece: false,
      fields: [
        {
          id: "",
          label: "Pastillas Freno Delanteras",
        },
      ],
    },
    {
      id: "pastillas_freno_traseras",
      label: "Pastillas Freno Traseras",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Pastillas Freno Traseras",
        },
      ],
    },
    {
      id: "discos_freno_delanteros",
      label: "Discos de freno delanteros",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Discos Freno Delanteros",
        },
      ],
    },
    {
      id: "discos_freno_traseros",
      label: "Discos de freno traseros",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Discos Freno Traseros",
        },
      ],
    },

    {
      id: "presion_alineamiento_neumaticos",
      label: "Estado neumáticos",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Estado neumáticos",
        },
      ],
    },
    {
      id: "suspension",
      label: "Suspensión",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Suspensión",
        },
      ],
    },
    {
      id: "embrague",
      label: "Embrague",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "embrague",
          label: "Embrague",
        },
      ],
    },
    {
      id: "liquido_frenos",
      label: "Líquido Frenos",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Líquido Frenos",
        },
      ],
    },
    {
      id: "luces",
      label: "Luces",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "luces",
          label: "Luces",
        },
      ],
    },
    {
      id: "bateria",
      label: "Batería",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "bateria",
          label: "Batería",
        },
      ],
    },
    {
      id: "liquido_limpiaparabrisas",
      label: "Líquido Limpiaparabrisas",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Líquido Limpiaparabrisas",
        },
      ],
    },
    {
      id: "liquido_refrigerante",
      label: "Líquido Refrigerante",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "",
          label: "Líquido Refrigerante",
        },
      ],
    },
    {
      id: "direccion",
      label: "Dirección",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "direccion",
          label: "Dirección",
        },
      ],
    },
    {
      id: "climatizacion",
      label: "Climatización",
      piece: false,
      variant: "normal",
      fields: [
        {
          id: "climatizacion",
          label: "Climatización",
        },
      ],
    },
  ];

  const InitScheme = (_isPiece, _callback) => {
    if (_callback) {
      const fieldsFiltered = PiezasOptionsFieldsArray.filter(
        (x) => x !== undefined && x.piece === _isPiece
      );
      _callback(fieldsFiltered);
    }
  };

  return { InitScheme, PiezasOptionsFieldsArray };
}
