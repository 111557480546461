import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, InputAdornment, Stack } from '@mui/material';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import FileOpenIcon from '@mui/icons-material/FileOpen';

function createData(id, name, email, phone, owner, confirmed, user_main_id, user_repairshop_id, image_url) {
  return {
    id,
    name,
    email,
    phone,
    owner,
    confirmed,
    user_main_id,
    user_repairshop_id,
    image_url,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Vehiculo',
  },
  {
    id: 'plate',
    numeric: false,
    disablePadding: false,
    label: 'Matricula',
  },
  {
    id: 'client',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'confirmed',
    numeric: false,
    disablePadding: false,
    label: 'Confirmada',
  },

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: 'primary.main' }}>
      <TableRow>

        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell

            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: 'white', fontWeight: '200', fontSize: '1.2rem' }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CustomTableRow({ row, vehicles, setEditModal, setInterventionData }) {

  const [open, setOpen] = useState(false);
  const openBox = () => {
    setOpen(!open)
  }

  const navigate = useNavigate()

  return (
    <>
      <TableRow
        hover
        onClick={() => {
          if (row.confirmed === "Pendiente") {
            setInterventionData(row);
            setEditModal(true);
          }
        }}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        sx={{ cursor: "pointer", backgroundColor: row.confirmed === "Confirmada" ? "#ffffff" : "#ffede7" }}
      >
        <TableCell padding="checkbox"></TableCell>
        <TableCell component="th" scope="row" padding="none">
          {row.name}
        </TableCell>
        <TableCell sx={{textTransform: "capitalize"}} align="left">{row.email}</TableCell>
        <TableCell align="left">{row.phone}</TableCell>
        <TableCell align="left">{row.owner}</TableCell>
        <TableCell sx={{display: "flex", alignItems: "center", justifyContent: "space-evenly", fontWeight: row.confirmed === "Confirmada" ? "normal" : "bold", color: row.confirmed === "Confirmada" ? "Black" : "primary.main", fontSize: "bold" }} align="left">
          {row.confirmed === "Confirmada" ? <DoneIcon sx={{ margin: "0", padding: "0" }} /> : <Tooltip arrow placement="top" title={`Pendiente de confirmar`}>
            <ErrorIcon  sx={{ color: "red" }} /> </Tooltip>}
            <IconButton sx={{color: "black"}} disabled={row.image_url == "null" ? true : false}  onClick={() => window.open(row.image_url, "_blank")}>
            <FileOpenIcon/>
          </IconButton>
        </TableCell>  
      </TableRow>

      <TableRow sx={{ cursor: "pointer", marginLeft: "25px", padding: "0" }}>
        <TableCell style={{ backgroundColor: "#f1f1f1", paddingBottom: 0, paddingTop: 0, boxShadow: "inset 0px 20px 30px -20px rgba(0,0,0,0.1)" }} colSpan={6}></TableCell>
      </TableRow>
    </>
  );
}

export default function DataTable({ interventions, setEditModal, setInterventionData, searchValue, setSearchValue, demo }) {
  const rows = interventions.map((intervention) => {


    let vehicleName = intervention.vehicle.vehicle_manufacturer?.name && intervention.vehicle.vehicle_model?.name
    ? `${intervention.vehicle.vehicle_manufacturer.name} ${intervention.vehicle.vehicle_model.name}`
    : intervention.vehicle.vehicle_manufacturer?.name
    ? intervention.vehicle.vehicle_manufacturer.name
    : intervention.vehicle.vehicle_model?.name
    ? intervention.vehicle.vehicle_model.name
    : "Sin especificar"


    return createData(
      intervention.id,
      `${new Date(intervention.created_at).toLocaleDateString()}`,
      `${vehicleName}`,
      intervention.plate || intervention.vehicle.plate || "Sin especificar",
      `${intervention.vehicle.user_owner.name_first} ${intervention.vehicle.user_owner.name_last}`,
      `${intervention.confirmed ? "Confirmada" : "Pendiente"}`,
      `${intervention.vehicle.user_owner.id_user_main}`,
      `${intervention.vehicle.id_user_repairshop}`,
      `${intervention.image_url}`,
    );
  });
  const rowHeight = 53

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('confirmed');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(Math.floor(window.innerHeight / rowHeight) - 6);


  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(Math.floor(window.innerHeight / rowHeight) - 6);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const navigate = useNavigate()
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows =

    stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )


  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "0 0 20px 0 rgba(0,0,0,0.14)" }}>
        <TableContainer>
          <Stack sx={{ flexDirection: "row", paddingRight: "20px", paddingLeft: "20px", justifyContent: "space-between", alignItems: "center", backgroundColor: "primary.main" }}>
            {demo === false && <Button
              onClick={() => navigate("/intervencion/crear")}
              sx={{

                color: "white",
                borderBottom: "1px solid white",
                paddingBottom: "0px",
                textTransform: "none",
                fontWeight: 200,
                fontSize: "1.1rem",
                "&:hover": { borderBottom: "2px solid white", marginBottom: "-1px" },
              }}
            >
              + Crear intervención
            </Button>}
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{
                paddingTop: "10px",
                width: "30%",
                color: "white",
                "& input": {
                  color: "white",
                },
                "& input::placeholder": {
                  color: "white",
                  opacity: 1,
                  fontWeight: "200",
                },
                "& .MuiInput-underline::before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline.Mui-focused::before": {
                  borderBottomColor: "white",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white" }} />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
              placeholder="Buscar"
              variant="standard"
            />
          </Stack>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />

            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const open = false;

                const openBox = () => {
                  open = !open;
                };

                return <CustomTableRow key={row.id} row={row} setEditModal={setEditModal} vehicles={interventions} setInterventionData={setInterventionData} />;
              })}
              {emptyRows > 0 && (
                <>
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </Box>
  );
}