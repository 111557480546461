import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { FileUploader } from "react-drag-drop-files";
import ModalComponent from "../../../components/Modal.Component";
import CloseIcon from '@mui/icons-material/Close';
import { useToast } from "../../../components/toastComponent/toast.Actions";
import { getStorageObject } from "../../../utils/LocalStorage";
import { uploadProfilePicture } from "../../../services/API/User.Routes";

const ProfilePictureComponent = () => {
  //STATES AND VARIABLES

  const [isHoveredState, setIsHoveredState] = useState(false);
  const [fileState, setFileState] = useState(null);
  const [editModeState, setEditModeState] = useState(false);
  const [dragState, setDragState] = useState(false);
  const [droppedState, setDroppedState] = useState(false);
  const [imageState, setImageState] = useState();
  const [loadingState, setLoadingState] = useState(false);

  const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

  // USE EFFECT

  useEffect(() => {
    const userData = getStorageObject("userData")

    const userId = userData?.id_user_firebase || userData?.id_user_repairshop;
    const imageUrl = `https://storage.googleapis.com/drivi-de77c.appspot.com/users/profilePictures/${userId}?timestamp=${Date.now()}`
    setImageState(imageUrl);
  }, []);

  // FUNCTIONS

  const { openCustomToast } = useToast();
  const handleError = () => {
    setImageState("/img/defaultProfilePicture.svg");
  };

  const onDrop = (file) => {
    setLoadingState(true);
  };

  const handleSubmit = (file) => {
    setLoadingState(true);
    const token = getStorageObject("token");
    const userData = getStorageObject("userData");
    const id = userData?.id_user_firebase || userData?.id_user_repairshop;
    try {
      uploadProfilePicture(token, id, file).then(({data: res}) => {
        setLoadingState(false);
        setDroppedState(true);
        openCustomToast("Imagen guardada", "success", "right", "top");
        setImageState(res.url + "?timestamp=" + Date.now() + '"');
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onTypeError = () => {
    openCustomToast("Solo se aceptan archivos JPEG, PNG, GIF, JPG", "error", true);
  };

  const onSizeError = () => {
    openCustomToast("El archivo es demasiado grande", "error", true);
  };

  const handleCloseEditModal = () => {
    setEditModeState(false);
    setDroppedState(false);
    setFileState(null);
  };

  return (
    <>
      <Stack
        height={"150px"}
        width={"150px"}
        onMouseEnter={() => setIsHoveredState(true)}
        onMouseLeave={() => setIsHoveredState(false)}
        onClick={() => setEditModeState(true)}
        sx={{
          position: "relative",
          boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.3)",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 6,
          transition: "0.3s",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <EditIcon
          sx={{
            color: "primary.main",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "10px",
            transition: "0.3s",
            height: "40px",
            width: "40px",
            position: "absolute",
            left: "0",
            right: "0",
            margin: "auto",
            zIndex: 2,
            opacity: isHoveredState ? 1 : 0,
          }}
        />

        <img
          style={{ transition: "0.3s", borderRadius: "50%", height: "150px", width: "150px", objectFit: "contain", filter: isHoveredState ? "brightness(0.9) saturate(0) blur(1px)" : "brightness(1)" }}
          src={imageState}
          onError={handleError}
          alt="logo"
        />
      </Stack>
      {editModeState && (
        <ModalComponent>
          <Stack sx={{ position: "absolute", left: "0", right: "0", margin: "auto", width: "300px", height: "200px", backgroundColor: "white", padding: "10px" }}>
            <IconButton sx={{ position: "absolute", top: "10px", right: "10px", zIndex: 2 }} onClick={handleCloseEditModal}>
              <CloseIcon />
            </IconButton>
            <FileUploader
              onSizeError={(error) => onSizeError()}
              onTypeError={(error) => onTypeError()}
              dropMessageStyle={{ display: "none" }}
              onDrop={(file) => onDrop(file)}
              onDraggingStateChange={(dragState) => setDragState(!dragState)}
              multiple={false}
              handleChange={(file) => handleSubmit(file)}
              name="file"
              types={fileTypes}
            >
              <Stack
                sx={{
                  marginTop: "50px",
                  transition: "0.3s",
                  boxShadow: !droppedState ? (dragState ? "inset 0 0 50px 0 rgba(0, 0, 0, 0.3)" : "inset 0 0 100px 100px #ea5828") : "inset 0 0 100px 100px limegreen",
                  backgroundColor: "white",
                  height: "145px",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                {loadingState ? (
                  <CircularProgress sx={{ color: "primary.main" }} />
                ) : droppedState ? (
                  <Typography sx={{ fontSize: "1.3rem", color: "white", fontWeight: "300" }}>¡Listo!</Typography>
                ) : dragState ? (
                  <Typography sx={{ fontWeight: "300" }}>Arrastra aquí tu imagen o haz click</Typography>
                ) : (
                  <Typography sx={{ fontSize: "1.3rem", color: "white", fontWeight: "300" }}>¡Suelta!</Typography>
                )}
              </Stack>
            </FileUploader>
          </Stack>
        </ModalComponent>
      )}
    </>
  );
};

export default ProfilePictureComponent;
