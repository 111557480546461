import { ApiCall_POST } from "..";



export const setWorkshopUserImpersonation = async (_data, _token) => {
    const response = await ApiCall_POST(`/api/admin/setWorkshopUserImpersonation`, _token, _data);
    return response;
}

// export const validateRecoveryLink = async (_token, _id) => {
//     const response = await ApiCall_GET(`/api/validateRecoveryLink?id=${_id}&token=${_token}`, _token);
//     return response;

// }