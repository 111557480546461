export const emailValidator = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase()) || "Ingresa un correo valido";
}

export const phoneValidator = (phone) => {
    const regex = /^\d{9}$/;
    return regex.test(String(phone)) || "Ingresa un número valido";
}

export const nameValidator = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\-'. ]+$/
    return regex.test(String(value)) || "Ingresa un nombre de taller válido";
}


export const contactValidator = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\-'. ]+$/
    return regex.test(String(value)) || "Ingresa un nombre válido";
}

export const urlValidator = (value) => {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    return regex.test(String(value)) || "Ingresa una url válida";
}

export const modelValidator = (value) => {
    const regex = /^[a-zA-Z0-9\s\-'&/]+$/;
    return regex.test(String(value)) || "Ingresa un nombre de modelo";
}

export const plateValidator = (value) => {
    const regex = /^(?=(?:[^0-9]*[0-9]){0,10}[^0-9]*$)[a-zA-Z0-9\s\-'&/]+$/;
    return regex.test(String(value)) || "Ingresa una matricula válida";
}

export const kmsValidator = (value) => {
    const regex = /^[0-9]{1,7}$/;
    return regex.test(String(value)) || "Ingresa un kilometraje válido";
}

export const yearValidator = (value) => {
    const regex = /^[0-9]{4}$/;
    return regex.test(String(value)) || "Ingresa un valor válido";
}