import React from 'react'
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { useAtom } from 'jotai';
import { toastOpenAtom, toastStyleAtom, toastTextAtom, toastMarginAtom  } from '../../context/atoms/Atoms.Storage';

const ToastComponent = () => {

const [isToastOpen, setIsToastOpen] = useAtom(toastOpenAtom);
const [toastText, setToastText] = useAtom(toastTextAtom);
const [toastStyle, setToastStyle] = useAtom(toastStyleAtom);
const [toastMargin, setToastMargin] = useAtom(toastMarginAtom);

const closeToast = () => {
    setIsToastOpen(false);
}

  return (
    <Snackbar onClose={closeToast} open={isToastOpen} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{ width: "fit-content", marginRight: toastMargin ? "35px" : "0px", marginTop: toastMargin ? "-18px" : "0px"}} autoHideDuration={3000}>
      <Alert severity={toastStyle} sx={{ width: "100%", borderRadius: "0px"}}>
        {toastText}
      </Alert>
    </Snackbar>
  );
}

export default ToastComponent