import { ApiCall_GET } from "..";

export const getAllManufacturers = async (_token) => {
    const response = await ApiCall_GET("/api/getAllManufacturers", _token);
    return response;
  }

  export const getByIdManufacturer = async (_token, _manufacturerId) => {
    const response = await ApiCall_GET(`/api/getByIdManufacturer?id_manufacturer=${_manufacturerId}`, _token);
    return response;
  }