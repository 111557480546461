import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  carOwnerAtom,
  changedItemsAtom,
  clientNameAtom,
  clientSurnameAtom,
  commentsInterventionAtom,
  dateInterventionAtom,
  kmInterventionAtom,
  manufacturerInterventionAtom,
  modelInterventionAtom,
  plateInterventionAtom,
  reviewsAtom,
  userVehicleAtom,
  vehicleInterventionAtom,
} from "../../../../context/atoms/Atoms.Storage";
import { useAtom } from "jotai";
import { Container, Grid, TextField } from "@mui/material";
import SideBarEditableContent from "./SideBarEditableContent.Component";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ClearIcon from "@mui/icons-material/Clear";

const labelFields_style = () => {
  return {
    fontSize: "1em",
    color: "#8f8f8f",
    padding: "0 0 0 0.2em",
    fontWeight: "1em",
    align: "left",
  };
};

const pieceBackgroundColor_style = (x) => {
  if (x.status === 2) {
    return "#e73d3d";
  } else if (x.status === 1) {
    return "#fff2e6";
  } else if (x.status === 0) {
    return "#f4fffc";
  }
};

const pieceBorderColor_style = (x) => {
  if (x.status === 2) {
    return "2px solid #e73d3d";
  } else if (x.status === 1) {
    return "2px solid #f48c0b";
  } else if (x.status === 0) {
    return "2px solid #4abe48";
  } else {
    return "2px solid transparent";
  }
};

const pieceTextColor_style = (x) => {
  if (x.status === 2) {
    return "#fff";
  } else if (x.status === 1) {
    return "#f48c0b";
  } else if (x.status === 0) {
    return "#4abe48";
  } else {
    return "black";
  }
};


export default function SideBarComponent({editModeState, setEditModeState}, _props) {
  const [carOwnerState, setCarOwnerState] = useAtom(carOwnerAtom);
  const [vehicleInterventionState, setVehicleInterventionState] = useAtom(vehicleInterventionAtom);
  const [userVehicleState, setUserVehicleState] = useAtom(userVehicleAtom);
  const [changedItemsState, setChangedItemsState] = useAtom(changedItemsAtom);
  const [reviewsState, setReviewsState] = useAtom(reviewsAtom);

  const [modelInterventionState, setModelInterventionState] = useAtom(modelInterventionAtom);
  const [manufacturerInterventionState, setManufacturerlInterventionState] = useAtom(manufacturerInterventionAtom);

  const [plateInterventionState, setPlateInterventionState] = useAtom(plateInterventionAtom);
  const [clientNameState, setClientNameState] = useAtom(clientNameAtom);
  const [clientSurnameState, setClientSurnameState] = useAtom(clientSurnameAtom);
  const [dateInterventionState, setDateInterventionState] = useAtom(dateInterventionAtom);
  const [kmInterventionState, setKmInterventionState] = useAtom(kmInterventionAtom);
  const [commentsInterventionState, setCommentsInterventionState] = useAtom(commentsInterventionAtom);

  const handleCommentsInterventionState = (event, value) => setCommentsInterventionState(value ?? event?.target?.value);
  useEffect(() => {
  }, [changedItemsState]);

  useEffect(() => {
    if (userVehicleState) {
      const manufacturer = userVehicleState.manufacturer.id;
      const model = userVehicleState.model.id;

      setManufacturerlInterventionState(manufacturer);
      setModelInterventionState(model);

      setPlateInterventionState(userVehicleState.plate);

      if (carOwnerState) {
        setClientNameState(carOwnerState.name_first);
        setClientSurnameState(carOwnerState.name_last);
      }

      setCommentsInterventionState(vehicleInterventionState.comments);

      const date = new Date();
      setDateInterventionState(date.toISOString().split("T")[0]);
      setKmInterventionState(userVehicleState.km);
    }
  }, [userVehicleState.km]);

  return (
    <Paper variant="outlined" sx={{ height: "calc(100vh - 1.7em)", p: { xs: 1, md: "0em 1em 0 0", borderRadius: "1em", position: "relative", border: "2px solid #d8dced", overflow: "hidden" } }}>
      {!vehicleInterventionState.confirmed &&
        (!editModeState ? (
          <EditNoteIcon
            onClick={() => setEditModeState(!editModeState)}
            sx={{
              position: "absolute",
              right: "0.5em",
              top: "0.8em",
              height: "1.5em",
              width: "1.5em",
              transition: "0.2s",
              "&:hover": {
                cursor: "pointer",
                scale: "1.2",
              },
            }}
          />
        ) : (
          <ClearIcon
            onClick={() => setEditModeState(!editModeState)}
            sx={{
              position: "absolute",
              right: "0.5em",
              top: "1em",
              height: "1.5em",
              width: "1.5em",
              transition: "0.2s",
              "&:hover": {
                cursor: "pointer",
                scale: "1.2",
              },
            }}
          />
        ))}

      <SideBarEditableContent editMode={editModeState} setEditMode={setEditModeState} isClosed={!_props.isClosed?.current} />
      <Grid
        container
        sx={{
          padding: "0.2em 0.3em 0.1em 0.8em",
          marginBottom: "0.2em",
        }}
      >
        <Grid item xs={12}>

          <TextField multiline minRows={3} placeholder="Nota al cliente..." value={commentsInterventionState} onChange={handleCommentsInterventionState} sx={{

            fontFamily: "inherit",
            color: "grey",
            height: "5.3em",
            width: "100%",
            "--Textarea-focusedInset": "var(--any, )",
            "--Textarea-focusedThickness": "0.05rem",
            "--Textarea-focusedHighlight": "rgba(13,110,253,.25)",
            "&::before": {
              transition: "box-shadow .15s ease-in-out",
            },
            "&:focus-within": {
              borderColor: "#ef5a28",
            },
            marginTop: "0.2em",
          }} />
        </Grid>
      </Grid>
      {!_props.isClosed?.current && _props.activeStep !== 2
        ? !editModeState && (
          <Grid
            container
            sx={{
              overflow: "hidden",
              height: "calc(100vh - 410px)",
              width: "98%",
              margin: "1.5em 0 0 1em",
              "& ::-webkit-scrollbar": {
                width: "12px",
              },
              "& ::-webkit-scrollbar-track": {
                backgroundColor: "#f5f5f5",
              },
              "& ::-webkit-scrollbar-thumb": {
                borderRadius: "10px",

                backgroundColor: "#a8a8a8",
              },
            }}
          >
            <Grid
              container
              sx={{
                height: "-webkit-fill-available",
                maxHeight: "95%",
                overflow: "auto",
                padding: "0 2% 0  0",
                alignContent: "flex-start",
              }}
            >
              {changedItemsState.length <= 1 && reviewsState.length <= 1 ? <Typography sx={{ textAlign: "center", padding: "1em 0 0 0" }}>Todavía no tienes piezas o itenes seleccionados</Typography> : null}

              <Grid item xs={12} sx={{ display: "contents" }}>
                {changedItemsState?.map((x, index) => {

                  if (x.id !== "") {
                    if (x.caducidadMeses || x.caducidadKm) {
                      return (
                        <Container
                          key={"key_sidebBar_estados_" + x.id}
                          height={"5em"}
                          sx={{
                            background: "#f4f3fb",
                            borderRadius: "5px",
                            margin: index > 1 ? "1em 0 0 0" : "0 0 0 0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0.3em 0.5em!important",
                            maxWidth: "90%",
                            overflow: "",
                          }}
                        >
                          <Typography
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              width: "fit-content",
                              fontWeight: "bold",
                              textWrap: "wrap",
                              maxWidth: "40%",
                              paddingTop: "0.5em",
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                          >
                            {x.name}
                          </Typography>

                          <Container
                            sx={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "60%",
                              paddingLeft: "0!important",
                              paddingRight: "0!important",
                              marginRight: "0!important",
                            }}
                          >
                            <Typography
                              xs={4}
                              sx={{
                                textAlign: "left",
                                width: "fit-content",
                                padding: "0 0.5em 0 0.5em",
                                color: "#828282",
                                border: "2px solid #d8d6e5",
                                borderRadius: "5px",
                                textWrap: "nowrap",
                                padding: "0.2em 0.5em",
                              }}
                            >
                              {`${x.caducidadMeses} M`}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                                margin: "0 0.5em 0 0.5em",
                                padding: "0.2em 0.5em",
                                color: "#828282",
                                border: "2px solid #d8d6e5",
                                borderRadius: "5px",
                                textWrap: "wrap",
                                overflow: "hidden",
                                width: "fit-content",
                                textWrap: "nowrap",
                              }}
                            >
                              {`${x?.caducidadKm?.toLocaleString()} Km`}
                            </Typography>
                          </Container>
                        </Container>
                      );
                    }
                  }
                })}
              </Grid>

              <Grid container item xs={12} sx={{ margin: "1em 0 0 0" }}>
                {reviewsState
                  ?.filter((item, index, self) => index === self.findIndex((x) => x.id === item.id))
                  .sort((a, b) => b.status - a.status)
                  .map((x, index) => {

                    if (x.id !== "") {
                      if (x.status >= 0 && x.status < 3) {
                        const radioLabels = ["Óptimo", "Regular", "Deficiente", "No informado"];
                        return (
                          <Grid
                            key={"key_sidebBar_estados_" + x.id}
                            container
                            xs={12}
                            align="center"
                            margin={"0 0 1em 0"}
                            sx={{ background: "#f4f3fb", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0.5em 0" }}
                          >
                            <Typography
                              xs={6}
                              gutterBottom
                              sx={{
                                textAlign: "left",
                                padding: "0.5em 0 0 0.5em",
                                fontWeight: "bold",
                              }}
                            >
                              {x.name}
                            </Typography>

                            <Typography
                              xs={6}
                              sx={{
                                textAlign: "left",
                                margin: "0 1em 0 0.5em",
                                padding: "0.2em 0.5em",
                                borderRadius: "5px",                     
                                border: pieceBorderColor_style(x),
                                background: x.isReplaced ? "#4abe48" : pieceBackgroundColor_style(x),                      
                                color: x.isReplaced ? "white" : pieceTextColor_style(x),
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {x.isReplaced ? "Reemplazado" : radioLabels[x.status]}

                          
                            </Typography>
                          </Grid>
                        );
                      }
                    }
                  })}
              </Grid>
            </Grid>
          </Grid>
        )
        : null}
      {/* {_props.isClosed ? <Typography sx={{ textAlign: "center", padding: "1em 0 0 0" }}>Intervención creada</Typography> : null} */}
      <img src={"/img/drivi_logo.svg"} style={{ width: "85px", position: "absolute", bottom: "10px", left: 0, right: 0, margin: "auto", backgroundColor: "white", padding: "0.5em", borderRadius: "5px" }} />
    </Paper>
  );
}
