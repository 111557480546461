import { Stack } from '@mui/material'
import React from 'react'
import { useAtom } from 'jotai';
import { sideBarDenseAtom } from '../context/atoms/Atoms.Storage';
import FadeInComponent from './fadeIn.Component';

const MainContent = ({ direction, justifyContent, children }) => {

    const [sideBarDense, setSideBarDense] = useAtom(sideBarDenseAtom);

    return (
        <FadeInComponent>
            <Stack alignItems={"center"}
                justifyContent={justifyContent || "flex-start"}
                direction={direction}
                sx={{
                    height: "100%",
                    overflow: "scroll",
                    width: `calc(100% - ${sideBarDense ? "100px" : "280px"})`,
                    transition: "0.1s",
                    position: "fixed",
                    right: 0,
                    margin: "0",
                    padding: "15px"
                }}>
                {children}
            </Stack>
        </FadeInComponent>

    )
}

export default MainContent