import { ApiCall_GET } from "..";

export const getAllByRepairshopIdVehicles = async (_token) => {
  const response = await ApiCall_GET(`/api/getallbyrepairshopidvehicles`, _token);
  return response;
}

export const getAllVehicleData = async (_token, _vehicleId) => {
  const response = await ApiCall_GET(`/api/getallvehicledata?id_vehicle=${_vehicleId}`, _token);
  return response;
}