const server_url = process.env.REACT_APP_SERVER_URL;


const threatFailReponse = async (_response, _urlFormatted) => {
    let text = _response.statusText;

    try {
        const text_message = await _response.text();

        if (text_message) {
            text = text_message;
        }

    }
    catch (error) {

        throw new Error(`HTTP error! Status: ${_response.status} - ${_response.statusText}`);
    }

    const message = `API Call ${_urlFormatted} succeeded with expected fail response : ${_response.status} - ${text}`;
    console.error(message);

}


export const ApiCall_GET = async (_apiUrl, _token, _data) => {

    let urlFormatted = _apiUrl;
    let headersOptions = {
        "Content-Type": "application/json"
    };

    if (_token) {
        headersOptions.Authorization = `Bearer ${_token}`;
    }

    if (_data) {
        const queryString = new URLSearchParams(_data).toString();
        urlFormatted = `${_apiUrl}?${queryString}`;
    }

    try {
        const response = await fetch(`${server_url}${urlFormatted}`, {
            method: 'GET',
            headers: headersOptions
        });

        if (!response.ok) {
            await threatFailReponse(response, urlFormatted);
            return null;
        }

        const text = await response.text();
        const json_text = JSON.parse(text);

        return {
            data: json_text,
            status: response.status,
            ok: response.ok
        };

    } catch (error) {
        console.error(error, error.status);


    }
}

export const ApiCall_POST = async (_apiUrl, _body, _token) => {
    try {

        let headersOptions = {
            "Content-Type": "application/json"
        };

        if (_token) {
            headersOptions.Authorization = `Bearer ${_token}`;
        }

        const response = await fetch(`${server_url}${_apiUrl}`, {
            method: 'POST',
            headers: headersOptions,
            body: JSON.stringify(_body)
        });


        if (!response.ok) {
            await threatFailReponse(response, _apiUrl);
            return;
        }

        const text = await response.text();
        const json_text = JSON.parse(text);

        return {
            data: json_text,
            status: response.status,
            ok: response.ok
        };

    } catch (error) {

        console.error('Error ao llamar la función del servidor:', error);

    }
}

export const ApiCall_POST_Files = async (_apiUrl, _formData, _token) => {
    try {
        let headersOptions = {};

        if (_token) {
            headersOptions.Authorization = `Bearer ${_token}`;
        }

        const response = await fetch(`${server_url}${_apiUrl}`, {
            method: 'POST',
            headers: headersOptions,
            body: _formData
        });

        if (!response.ok) {
            await threatFailReponse(response, _apiUrl);
            return;
        }

        const text = await response.text();
        const json_text = JSON.parse(text);

        return {
            data: json_text,
            status: response.status,
            ok: response.ok
        };
    } catch (error) {

        console.error('Error ao llamar la función del servidor:', error);

    }
}

export const ApiCall_PATCH = async (_apiUrl, _body, _token) => {
    try {


        let headersOptions = {
            "Content-Type": "application/json"
        };

        if (_token) {
            headersOptions.Authorization = `Bearer ${_token}`;
        }

        const response = await fetch(`${server_url}${_apiUrl}`, {
            method: 'PATCH',
            headers: headersOptions,
            body: JSON.stringify(_body)
        });


        if (!response.ok) {
            await threatFailReponse(response, _apiUrl);
            return;
        }

        const text = await response.text();
        const json_text = JSON.parse(text);

        return {
            data: json_text,
            status: response.status,
            ok: response.ok
        };

    } catch (error) {

        console.error('Error ao llamar la función del servidor:', error);

    }
}
