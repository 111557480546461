import React, { useEffect, useState } from 'react'
import MainContent from '../../components/MainContent.component'
import HeaderComponent from '../../components/Header.Component'
import SettingsIcon from '@mui/icons-material/Settings';
import { useForm } from 'react-hook-form';
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Stack, Typography } from '@mui/material';
import { useToast } from '../../components/toastComponent/toast.Actions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getStorageObject } from '../../utils/LocalStorage';
import { ChangePassword } from '../../services/API/Auth.Routes';

const ConfigurationPage = () => {

  const [responsiveState, setResponsiveState] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setResponsiveState(window.innerWidth < 968);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { openCustomToast } = useToast()

  const { register, handleSubmit } = useForm()

  const [showPasswordState, setShowPasswordState] = useState(false);
  const [showConfirmPasswordState, setShowConfirmPasswordState] = useState(false);
  const [showOldPasswordState, setShowOldPasswordState] = useState(false);
  const [disableButtonState] = useState(false);

  const [oldPasswordState, setOldPasswordState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState("");

  const handleClickShowPassword = () => {
    setShowPasswordState(!showPasswordState);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPasswordState(!showConfirmPasswordState);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPasswordState(!showOldPasswordState);
  }

  const onSubmit = async (data) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\dáéíóúÁÉÍÓÚñÑ@$!%*?&_]{6,}$/;

    if (!passwordRegex.test(passwordState)) {
      openCustomToast("La contraseña debe tener al menos 6 caracteres, una letra mayúscula, una letra minúscula y un número", "error", false);
    } else if (passwordRegex.test(passwordState)) {
      if (passwordState === confirmPasswordState && passwordState !== "" && confirmPasswordState !== "" && passwordRegex.test(passwordState)) {
        const token = getStorageObject("token");
        const id = getStorageObject("userData").id;

        ChangePassword(token, passwordState, id, oldPasswordState || "no").then((response) => {
          if (response) {
            openCustomToast("Contraseña cambiada", "success", true);
            setOldPasswordState("");
            setPasswordState("");
            setConfirmPasswordState("");
          } else {
            openCustomToast("La contraseña actual es incorrecta", "error", true);
          }
        });
      } else {
        openCustomToast("Las contraseñas no coinciden", "error", true);
      }
    }
  };

  return (
    <MainContent>
      <HeaderComponent text={"Configuración"} icon={<SettingsIcon sx={{ color: "primary.main", width: "40px", height: "40px" }} />} />

      <Stack margin={"20px 0"} padding={"20px 0"} gap={"20px"} sx={{ width: responsiveState ? "90%" : "50%", maxWidth: "1000px", alignItems: "center", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.2)" }}>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "200" }}>Cambia tu contraseña</Typography>
        <form style={{ padding: "20px", width: "fit-content", display: "flex", flexDirection: "column", gap: "0px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Contraseña actual</InputLabel>
            <Input
              {...register("password-old")}
              value={oldPasswordState}
              onChange={(e) => setOldPasswordState(e.target.value)}
              id="standard-adornment-password"
              type={showOldPasswordState ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowOldPassword} onMouseDown={handleClickShowOldPassword}>
                    {showOldPasswordState ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Nueva contraseña</InputLabel>
            <Input
              {...register("password")}
              value={passwordState}
              onChange={(e) => setPasswordState(e.target.value)}
              id="standard-adornment-password"
              type={showPasswordState ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleClickShowPassword}>
                    {showPasswordState ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Confirmar contraseña</InputLabel>
            <Input
              {...register("password-confirm")}
              value={confirmPasswordState}
              onChange={(e) => setConfirmPasswordState(e.target.value)}
              id="standard-adornment-password"
              type={showConfirmPasswordState ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleClickShowConfirmPassword}>
                    {showConfirmPasswordState ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Button
              disabled={disableButtonState}
              style={{ backgroundColor: disableButtonState ? "grey" : "primary.main", color: "white", marginTop: "30px", borderRadius: "0" }}
              width={"100%"}
              type="submit"
              variant="contained"
            >
              Cambiar contraseña
            </Button>
          </FormControl>
        </form>
      </Stack>
    </MainContent>
  );
}

export default ConfigurationPage