import { ApiCall_GET } from "..";

export const getAllCarcomponents = async (_token) => {
    const response = await ApiCall_GET("/api/getallcarcomponents", _token);
    return response;
  }

export const getByRepairshopIdCarcomponents = async (_token, _repairshopId) => {
    const response = await ApiCall_GET(`/api/getbyrepairshopidcarcomponents?id_repairshop=${_repairshopId}`, _token);
    return response;
}