import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Container, Typography, Button, TextField, ThemeProvider, createTheme, Link, Checkbox } from '@mui/material'
import { motion } from 'framer-motion'
import { getbyUuidLead, postLead } from '../../services/API/Lead.Routes.js'

function QuestionCard({
  question,
  text1,
  text2,
  placeholder,
  dataRegister,
  setQuestionNumberState,
  questionNumberState,
  register,
  errors,
  getValues,
  uuidState,
  setFirstName,
  setEmailState
}) {

  const theme = createTheme({
    typography: {
      fontFamily: 'Inter, sans-serif'
    }
  })

  const [buttonDisabledState, setButtonDisabledState] = useState(true)

  const nameValidator = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\-'. ]+$/
    return regex.test(String(value)) || "Ingresa un nombre de válido";
  }

  const name2Validator = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\-'. ]+$/
    return regex.test(String(value)) || "Ingresa un nombre válido";
  }

  const numberValidator = (value) => {
    const regex = /^[0-9]+$/
    return regex.test(String(value)) || "Ingresa un nombre válido";
  }

  const phoneValidator = (phone) => {
    const regex = /^.+$/;
    return regex.test(String(phone)) || "Ingresa un número valido";
  }

  const addressValidator = (address) => {
    const regex = /^[a-zA-Z0-9\s,'\/.\-áéíóúÁÉÍÓÚüÜñÑ]+$/;
    return regex.test(address) || "Ingresa una dirección válida";
  };

  const emailValidator = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email) || "Ingresa un correo válido";
  }


  const enableButton = (event) => {
    setButtonDisabledState(false)
  }

  const [provinceDisabledState, setProvinceDisabledState] = useState(true)
  const [cpDisabledState, setCpDisabledState] = useState(true)
  const [cityDisabledState, setCityDisabledState] = useState(true)
  const [addressDisabledState, setAddressDisabledState] = useState(true)

  const PreventDefault = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }



  return (
    <>
      <ThemeProvider theme={theme} />
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Stack gap={"10px"} alignItems={"center"} justifyContent={"center"} width={"100%"} height={"fit-content"} maxWidth={"90%"} sx={{ padding: "20px" }}>
          {text1 && (
            <Typography sx={{ fontFamily: "Inter", fontWeight: "100", textAlign: "center" }} color={"#e94e1b"} fontWeight={"100"} variant="h2">
              {text1}
            </Typography>
          )}
          <Typography sx={{ fontFamily: "Inter", fontWeight: "300" }} fontSize={"1.5rem"} textAlign={"center"} maxWidth={"500px"} variant="subtitle1">
            {text2}
          </Typography>
          <Typography maxWidth={"300px"} sx={{ fontFamily: "Inter", fontWeight: "700" }} fontSize={"1.5rem"} textAlign={"center"}>
            {question}
          </Typography>

          {questionNumberState !== -1 && questionNumberState !== 1 && questionNumberState !== 2 && questionNumberState !== 3 && questionNumberState !== -2 && (
            <TextField
              onKeyDown={enableButton}
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              {...register(dataRegister, { validate: name2Validator })}
              placeholder={placeholder && placeholder}
            ></TextField>
          )}

          {questionNumberState == -1 && (
            <TextField
              onKeyUp={() => {
                setEmailState(getValues().email);
              }}
              onKeyDown={enableButton}
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              {...register(dataRegister, { validate: emailValidator })}
              placeholder={placeholder && placeholder}
            ></TextField>
          )}

          {questionNumberState == -2 && (
            <TextField
              onKeyUp={() => {
                setFirstName(getValues().first_name);
              }}
              onKeyDown={() => {
                enableButton();
                setFirstName(getValues().first_name);
              }}
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              {...register(dataRegister, { validate: name2Validator })}
              placeholder={placeholder && placeholder}
            ></TextField>
          )}

          {questionNumberState == 1 && (
            <>
              <Stack marginTop={"20px"} gap={"10px"}>
                <Stack gap={"10px"} direction={"row"}>
                  <TextField
                    onKeyPress={(e) => {
                      PreventDefault(e);
                    }}
                    onKeyDown={() => setProvinceDisabledState(false)}
                    {...register("province", { validate: nameValidator })}
                    gap={"10px"}
                    placeholder="Provincia"
                  />
                  <TextField
                    onKeyPress={(e) => {
                      PreventDefault(e);
                    }}
                    onKeyDown={() => setCpDisabledState(false)}
                    {...register("cp", { validate: numberValidator })}
                    gap={"10px"}
                    placeholder="Código postal"
                  />
                </Stack>
                <Stack gap={"10px"} direction={"row"}>
                  <TextField
                    onKeyPress={(e) => {
                      PreventDefault(e);
                    }}
                    onKeyDown={() => setCityDisabledState(false)}
                    {...register("city", { validate: nameValidator })}
                    gap={"10px"}
                    placeholder="Municipio"
                  />
                  <TextField
                    onKeyPress={(e) => {
                      PreventDefault(e);
                    }}
                    onKeyDown={() => setAddressDisabledState(false)}
                    {...register("address", { validate: addressValidator })}
                    gap={"10px"}
                    placeholder="Dirección"
                  />
                </Stack>
              </Stack>
            </>
          )}

          {questionNumberState === 2 && (
            <TextField
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              onKeyDown={enableButton}
              type="number"
              {...register("workers", { validate: numberValidator })}
              gap={"10px"}
              placeholder="Número"
            />
          )}
          {questionNumberState === 3 && (
            <TextField
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              onKeyDown={enableButton}
              {...register("phone", { validate: phoneValidator })}
              gap={"10px"}
              placeholder="Número de telefono"
            />
          )}

          {questionNumberState !== 4 && questionNumberState !== 1 && (
            <Button
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              style={
                buttonDisabledState || Object.keys(errors).length > 0
                  ? {
                    width: "200px",
                    marginTop: "10px",
                    fontSize: "1rem",
                    textAlign: "center",
                  }
                  : {
                    width: "200px",
                    marginTop: "10px",
                    backgroundColor: "primary.main",
                    color: "white",
                    fontSize: "1rem",
                    textAlign: "center",
                  }
              }
              variant="contained"
              disabled={buttonDisabledState || Object.keys(errors).length > 0 ? true : false}
              onClick={() => {
                postLead({ ...getValues() }, uuidState);
                setQuestionNumberState(questionNumberState + 1);
              }}
            >
              {(questionNumberState == -2 || questionNumberState == -1 || questionNumberState == 0 || questionNumberState == 2 || questionNumberState == 3) && "Enviar"}
              {questionNumberState === 1 && "Continuar"}
            </Button>
          )}

          {questionNumberState === 1 && (
            <Button
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              style={
                provinceDisabledState || cpDisabledState || cityDisabledState || addressDisabledState || Object.keys(errors).length > 0
                  ? {
                    width: "200px",
                    marginTop: "10px",
                    fontSize: "1rem",
                    textAlign: "center",
                  }
                  : {
                    width: "200px",
                    marginTop: "10px",
                    backgroundColor: "primary.main",
                    color: "white",
                    fontSize: "1rem",
                    textAlign: "center",
                  }
              }
              variant="contained"
              disabled={provinceDisabledState || cpDisabledState || cityDisabledState || addressDisabledState || Object.keys(errors).length > 0 ? true : false}
              onClick={() => {
                postLead({ ...getValues() }, uuidState);
                setQuestionNumberState(questionNumberState + 1);
              }}
            >
              {(questionNumberState === 0 || questionNumberState == 2) && "Enviar"}
              {questionNumberState === 1 && "Continuar"}
            </Button>
          )}

          {questionNumberState == 4 && (
            <Button
              onKeyPress={(e) => {
                PreventDefault(e);
              }}
              style={
                Object.keys(errors).length > 0
                  ? {
                    width: "200px",
                    marginTop: "10px",
                    fontSize: "1rem",
                    textAlign: "center",
                  }
                  : {
                    width: "200px",
                    marginTop: "10px",
                    backgroundColor: "primary.main",
                    color: "white",
                    fontSize: "1rem",
                    textAlign: "center",
                  }
              }
              disabled={Object.keys(errors).length > 0 ? true : false}
              variant="contained"
              onClick={() => {
                postLead({ ...getValues() }, uuidState);
                setQuestionNumberState(6);
              }}
            >
              Finalizar
            </Button>
          )}
        </Stack>
      </motion.div>
    </>
  );
}

export default function LeadsPage({ }) {

  const [firstNameState, setFirstNameState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [ipState, setIpState] = useState("");
  const [uuidState, setUuidState] = useState("");
  const [idComercialState, setIdComercialState] = useState();
  const [questionNumberState, setQuestionNumberState] = useState()
  const [doneState] = useState(false)
  const [acceptedTermsState, setAcceptedTermsState] = useState(false)

  useEffect(() => {

    const queryString = window.location.search;
    const parametrosDaConsulta = queryString.replace(/^\?/, "");
    const parametros = new URLSearchParams(parametrosDaConsulta);

    let _name = "#Comercial#";
    let _uuid;
    let _email = "";
    let _ip = "";

    const _idcomercial = parametros.get("idcomercial");

    if (_idcomercial === "true") {

      _uuid = createUuid();

    } else {
      _name = parametros.get("name");
      _uuid = parametros.get("popups_uuid");
      _email = parametros.get("email");
      _ip = parametros.get("ip");
    }

    getbyUuidLead(parametros.get("popups_uuid")).then(({ data: leadData, status, ok }) => {
      if (leadData && leadData !== false) {
        setQuestionNumberState(leadData);
      } else {
        const newLeadData = {
          firstName: _name,
          uuid: parametros.get("popups_uuid"),
          email: _email,
          ip: _ip
        };
        postLead(newLeadData, parametros.get("popups_uuid"));
        setQuestionNumberState(0);
      }
    }).catch(error => { });

    if (_idcomercial === "true") {
      setQuestionNumberState(5);
    }

    setQuestionNumberState(_idcomercial ? -2 : 0);

    setFirstNameState(_name ? _name : "")
    setEmailState(_email);
    setIpState(_ip);
    setUuidState(_uuid);
    setIdComercialState(_idcomercial);



  }, []);

  const { register, getValues, handleSubmit, formState: { errors } } = useForm({ mode: "all" });

  const createUuid = () => {
    const cryptoObj = window.crypto || window.msCrypto;
    const buffer = new Uint8Array(16);
    cryptoObj.getRandomValues(buffer);

    buffer[6] = (buffer[6] & 0x0f) | 0x40;
    buffer[8] = (buffer[8] & 0x3f) | 0x80;

    const hexDigits = '0123456789abcdef';
    const uuid = Array.from(buffer).map(byte => hexDigits[byte >> 4] + hexDigits[byte & 0x0f]).join('');

    return `${uuid.slice(0, 8)}-${uuid.slice(8, 12)}-${uuid.slice(12, 16)}-${uuid.slice(16, 20)}-${uuid.slice(20)}`;
  }

  const onSubmit = (data) => {

    let reqData = data
    reqData.first_name = firstName
    reqData.email = emailState
    reqData.ip = ipState
    reqData.uuid = uuidState
    setQuestionNumberState(questionNumberState + 1)
    postLead(reqData, uuidState)
  }

  const onClickFinishForm = (e) => {
    contrataInmediata(e)
  }

  const onClickContrataInmediata = (e) => {
    contrataInmediata(e)
  }

  const contrataInmediata = (e) => {
    postLead(({ ...getValues() }), uuidState);
    const { workers, email } = getValues();

    if (workers > 3) {
      window.open(`https://buy.stripe.com/5kA28N6Ot0rfh1e8wx?prefilled_promo_code=MESGRATIS&prefilled_email=${emailState}`, '_blank', 'noopener');
    }
    else {
      window.open(`https://buy.stripe.com/dR628NfkZ0rfdP29AA?prefilled_promo_code=MESGRATIS&prefilled_email=${emailState}`, '_blank', 'noopener');
    }

    e.preventDefault();
  }

  const onClickContrataCondicional = (e) => {

    const { workers, email } = getValues();
    if (workers > 3) {
      window.open(`https://buy.stripe.com/6oE28Nc8N4Hv26keUW?prefilled_email=${emailState}&prefilled_promo_code=MESGRATIS`, '_blank', 'noopener');
    }
    else {
      window.open(`https://buy.stripe.com/bIYbJn3Ch5Lz9yM4gj?prefilled_email=${emailState}&prefilled_promo_code=MESGRATIS`, '_blank', 'noopener');
    }

  }

  const name = firstNameState

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
      <Container sx={{ width: "fit-content", maxWidth: "600px", height: "calc(100vh - 10px)", width: "100%", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center", justifyContent: "center" }}>

        <img style={{ height: "35px", marginRight: "0px" }} src="../../img/drivi_logo.svg" alt="" />

        <form>
          {!doneState && <>


            {questionNumberState == -2 && idComercialState && <QuestionCard
              text2={`Ya estás muy cerca de activar el regreso de tus clientes en piloto automático con la magia de Drivi.`}
              question={"¿Cuál es tu nombre?"}
              placeholder={"Nombre (sin apellidos)"}
              dataRegister={"first_name"}
              setQuestionNumberState={setQuestionNumberState}
              setFirstName={setFirstNameState}
              register={register}
              errors={errors}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              getValues={getValues}
              questionNumberState={questionNumberState}
              uuidState={uuidState} />}

            {questionNumberState == -1 && idComercialState && <QuestionCard
              question={"¿Cuál es email?"}
              placeholder={"Email"}
              dataRegister={"email"}
              setQuestionNumberState={setQuestionNumberState}
              register={register}
              errors={errors}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              getValues={getValues}
              questionNumberState={questionNumberState}
              setEmailState={setEmailState}
              uuidState={uuidState} />}

            {questionNumberState == 0 && name && <QuestionCard
              text1={!idComercialState ? `¡Genial ${name}!` : null}
              question={"¿Cómo se llama tu taller?"}
              placeholder={"Nombre del taller"}
              dataRegister={"name_workshop"}
              setQuestionNumberState={setQuestionNumberState}
              register={register}
              errors={errors}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              getValues={getValues}
              questionNumberState={questionNumberState}
              uuidState={uuidState}
              isSeller={idComercialState ? true : false} />}

            {questionNumberState == 1 && <QuestionCard

              text2={`Vale, ahora un par de preguntas facilitas:`}
              question={"La primera ¿Dónde está?"}
              setQuestionNumberState={setQuestionNumberState}
              register={register}
              dataRegister={"address"}
              errors={errors}
              questionNumberState={questionNumberState}
              getValues={getValues}
              onSubmit={onSubmit}
              uuidState={uuidState}
              isSeller={idComercialState ? true : false}
              handleSubmit={handleSubmit}>

            </QuestionCard>}

            {questionNumberState == 2 && <QuestionCard

              text2={`Y la segunda`}
              question={"¿Cuántos trabajadores sois en el taller?"}
              dataRegister={"workers"}
              setQuestionNumberState={setQuestionNumberState}
              register={register}
              errors={errors}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              uuidState={uuidState}
              isSeller={idComercialState ? true : false}
              getValues={getValues}
              questionNumberState={questionNumberState} />}

            {questionNumberState == 3 && <QuestionCard

              text2={`¡Perfecto, nos pondremos en contacto contigo muy pronto! `}
              question={"¿Nos das un teléfono de contacto? "}
              setQuestionNumberState={setQuestionNumberState}
              register={register}
              dataRegister={"phone"}
              placeholder={"Teléfono"}
              errors={errors}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              uuidState={uuidState}
              isSeller={idComercialState ? true : false}
              getValues={getValues}
              questionNumberState={questionNumberState} />}

            {questionNumberState == 4 && <QuestionCard
              question={`Por cierto ${name}, que se nos olvidó preguntarte los apellidos!`}
              placeholder={"Apellidos"}
              dataRegister={"last_name"}
              setQuestionNumberState={setQuestionNumberState}
              register={register}
              errors={errors}
              onSubmit={onSubmit}
              uuidState={uuidState}
              isSeller={idComercialState ? true : false}
              getValues={getValues}
              handleSubmit={handleSubmit}
              questionNumberState={questionNumberState} />}</>}

          {(questionNumberState == 5) && <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.9, duration: 1 }}>
              <Typography sx={{ fontFamily: "Inter", fontWeight: "100", width: "100%", textAlign: "center" }} color={"#e94e1b"} fontWeight={"100"} variant='h3'>¡Recibirás noticias nuestras!</Typography>
              <Typography sx={{ paddingTop: "10px", fontFamily: "Inter", fontWeight: "300", width: "100%", textAlign: "center" }} fontSize={"1.5rem"} textAlign={"center"} maxWidth={"800px"} variant='subtitle1'>Gracias, {name}.</Typography>
            </motion.div>
          </Stack>}

          {(questionNumberState == 6) && <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.9, duration: 1 }}>
              <Typography sx={{ paddingTop: "10px", fontFamily: "Inter", fontWeight: "300", width: "100%", textAlign: "center" }} fontSize={"1.5rem"} textAlign={"center"} maxWidth={"800px"} variant='subtitle1'>Ahora te redirigimos a la pasarela de pago, <br /> pero no te preocupes <br />
                <b>te avisaremos por email dos días antes de <br /> terminar la prueba</b><br />
                por si no deseas seguir y no se hará ningún <br /> cargo.
              </Typography>

              <Stack sx={{ width: "100%", alignItems: "center", justifyContent: "center", marginBottom: "10px" }} direction={"row"} gap={"10px"}>
                <Checkbox checked={acceptedTermsState} onChange={() => setAcceptedTermsState(!acceptedTermsState)} />
                <Typography sx={{ fontFamily: "Inter", fontWeight: "300" }}>Aceptar <Link href="https://drivimobility.com/terminosycondiciones-taller.html" target="_blank"> terminos y condiciones </Link></Typography>
              </Stack>
              <Stack sx={{ width: "100%", alignItems: "center", justifyContent: "center" }} direction={"row"} gap={"10px"}>

                <Button disabled={!acceptedTermsState} onClick={(e) => { idComercialState ? setQuestionNumberState(7) : onClickFinishForm(e) }} variant="contained">Continuar</Button>
              </Stack>
            </motion.div>
          </Stack>}

          {(questionNumberState == 7) && <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.9, duration: 1 }}>
              <Typography sx={{ fontFamily: "Inter", fontWeight: "100", width: "100%", textAlign: "center" }} color={"#e94e1b"} fontWeight={"100"} variant='h3'></Typography>
              <Typography sx={{ paddingTop: "10px", fontFamily: "Inter", fontWeight: "300", width: "100%", textAlign: "center" }} fontSize={"1.5rem"} textAlign={"center"} maxWidth={"800px"} variant='subtitle1'></Typography>
              <Stack sx={{ width: "100%", alignItems: "center", justifyContent: "center" }} direction={"row"} gap={"10px"}>

                <Button variant="contained" onClick={(e) => onClickContrataInmediata(e)}>Contratación inmediata</Button>
                <Button variant="contained" onClick={(e) => onClickContrataCondicional(e)}>Condicional</Button>
              </Stack>
            </motion.div>
          </Stack>}

        </form>
      </Container>
    </motion.div >
  )
}
