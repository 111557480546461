import * as React from "react";

import { useAtom } from "jotai";
import { userVehicleAtom, vehicleInterventionAtom, fieldsMapAtom } from "../../../../context/atoms/Atoms.Storage";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import ChangedItemsItem from "./ChangedItems.Item";
import { NormalizeId } from "../../../../utils/FormatValues";
import SetInterventionPartValue from "../actions/SetInterventionPartValue";

function InitChangedItems_State() {

  const [fieldsMapState, setFieldsMapState] = useAtom(fieldsMapAtom);

  let PiezasOptionsFieldsArray = [];

  return { fieldsMapState, setFieldsMapState, PiezasOptionsFieldsArray };
}

function InitChangedItems_Values(name, value_month, value_kms) {
  let fieldsArray;

  const [userVehicleState, setUserVehicleState] = useAtom(userVehicleAtom);

  const startItems = (vehicleInterventionState, carcomponents) => {
    if (userVehicleState.pieces) {
      if (vehicleInterventionState?.pieces.length > 0) {
        let partsAlreadyReaded = [];
        fieldsArray = carcomponents;
        vehicleInterventionState.pieces.forEach((name) => {
          if (!partsAlreadyReaded.find((x) => x !== undefined && x === name)) {
            partsAlreadyReaded.push(name);

            let findItem = carcomponents.find((x) => x !== undefined && NormalizeId(x.label) === NormalizeId(name));

            if (findItem) {
              findComponentByName(findItem.id, findItem.fields[0].defaultValues.caducidadMeses, findItem.fields[0].defaultValues.caducidadKm, findItem.fields[0].defaultValues.isReplaced);
            }
          }
        });
      }
    }
  };

  const findComponentByName = (_id, _value_months, _value_kms, _value_checkbox) => {
    fieldsArray.forEach((component) => {
      if (_id === component.id) {
        if (component.fields) {
          if (component.fields[0].action) {
            component.fields[0].action(null, _value_months, _value_kms, _value_checkbox, component.fields[0].id, component.label, "TextChange_" + component.fields[0].id, "setTextChange_" + component.fields[0].id);
          }
        }
      }
    });
  };

  return { startItems };
}

function ChangedItems({ carcomponents }, _props) {
  const { startItems } = InitChangedItems_Values();
  const { setValues } = SetInterventionPartValue();
  const { fieldsMapState, setFieldsMapState } = InitChangedItems_State();

  const [vehicleInterventionState, setVehicleInterventionState] = useAtom(vehicleInterventionAtom);

  const orderedIds = [7, 1, 6, 2, 3];

  useEffect(() => {
    startItems(vehicleInterventionState, carcomponents);
  }, [vehicleInterventionState]);

  const setActionFields = () => {
    carcomponents.forEach((pieza) => {
      pieza.fields.forEach((field) => {
        const idValue = field.id;
        const labelValue = pieza.label;

        field.action = (event, value_months, value_kms, value_checkbox, id, name, getState, setState) =>
          handleTextChange(event, value_months, value_kms, value_checkbox, idValue, labelValue, `TextChange_${idValue}`, `setTextChange_${idValue}`);
      });
    });

    const handleTextChange = (event, _value_months, _value_kms, _value_checkbox, _id, name, getState, setState) => {

      const data_values = { value_months: _value_months, value_kms: _value_kms, value_checkbox: _value_checkbox };
      setValues(event, true, data_values, _id, name, getState, fieldsMapState, setFieldsMapState);

    };


    return carcomponents && carcomponents.map((element) => {


      let isSelected = false;

 

      if (vehicleInterventionState.plate == "5759FZL") {

      if (element.id === 1 || element.id === 6 || element.id === 7) {
        isSelected = true;
      }}
      
      return <ChangedItemsItem key={`changedItemsItem_${element.id}`} id={element.id} item={element} borderColor="red" selected={isSelected} isReplaced={element.isReplaced}></ChangedItemsItem>;
    });

  };

  return (
    <Grid
      container
      id={"ChangedItems_container"}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ width: "fit-content", maxWidth: "60%", color: "grey", fontSize: "1.15em", color: "#8f8f8f", fontWeight: "1em", align: "left" }}>
        Selecciona las piezas que has sustituido en el vehículo
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ width: "fit-content", maxWidth: "60%", color: "grey", fontSize: "1.15em", color: "#8f8f8f", fontWeight: "1em", align: "left" }}>
        e indica la caducidad de la nueva pieza en número de meses y kilómetros.
      </Typography>
      <Grid container id={"ChangedItems_container_fields"} xs={12} lg={12} sx={{ marginTop: 2, padding: 0, display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "center", justifyContent: "center", alignItems: "center" }}>
        {setActionFields()}
      </Grid>
    </Grid>
  );
}

export default ChangedItems;
