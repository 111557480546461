// TypePart.js

// Enum for types of parts
const FieldType = {
    MONTH: 1,
    KM: 2,
    STATUS: 3,
    REPLACED: 4
};


export default FieldType;