import { Timestamp } from "firebase/firestore";
import { NormalizeId } from "../../utils/FormatValues";
import InitChangedItems_State from "../../pages/intervention/components/schemes/PartsFields.scheme";

function VehicleMapper() {
    const { PiezasOptionsFieldsArray } = InitChangedItems_State();

    const InitVehicleMapper = (data) => {

        const vehicle = VehicleEntity;

        vehicle.id = data.id
        vehicle.buyDate = data.buyDate ?? null;
        vehicle.emissions = data.id_vehicle_emissions ?? null;
        vehicle.energy = data.id_vehicle_energy ?? null;
        vehicle.km = data.km ?? null;
        vehicle.kmPerYear = data.km_year ?? null;
        vehicle.lastItv = data.date_lastitv ?? null;
        vehicle.manufacturer = data.manufacturer ?? null;
        vehicle.model = data.model ?? null;
        if (data.parts) {
            vehicle.parts = PartsMapper(data.parts);
        } else {
            vehicle.parts = [];
        }
        vehicle.plate = data.plate ?? null;
        vehicle.safescore = data.safescore ?? null;
        vehicle.techSpec1 = data.techSpec1 ?? null;
        vehicle.techSpec2 = data.techSpec2 ?? null;
        vehicle.userIdOwner = data.id_user_owner ?? null;
        vehicle.userIdRepairShop = data.id_user_repairshop ?? null;
        vehicle.version = data.version ?? null;
        vehicle.vin = data.vin ?? null;
        vehicle.workshop.driviId = data.id_user_repairshop ?? null;
        // vehicle.workshop.placeId = data.workshop.placeId ?? null;
        vehicle.year = data.year ?? null;

        return vehicle;
    }

    const InterventionsMapper = (data_interventions_list) => {

        if (data_interventions_list) {

            if (data_interventions_list.length > 0) {
                const map_interventions =
                    data_interventions_list.map(element => {
                        return {
                            comments: element.comments,
                            confirmed: element.confirmed,
                            date: element.date,
                            id: element.id,
                            imageUrl: element.imageUrl,
                            pieces: element.pieces,
                            revisions: RevisionsMapper(element.revisions),
                            vehicleKm: element.vehicleKm,
                            vehiclePlate: element.vehiclePlate,
                            workshopId: element.workshopId
                        }
                    });

                return map_interventions;
            } else {
                return [];
            }
        }
    }


    const RevisionsMapper = (data) => {

        if (data) {

            if (data.length > 0) {
                const map_revisions =
                    data.map(element => {
                        return {
                            name: element.name,
                            status: element.status
                        }
                    });

                return map_revisions;
            }
            else {
                return [];
            }
        }
    }


    const PartsMapper = (data_parts_list) => {
        if (data_parts_list) {

            if (data_parts_list.length > 0) {
                const map_parts =
                    data_parts_list.map(element => {

                        const item = {
                            piece: isPiece(element.id),
                            name: element.name,
                            status: element.status,
                            lastInterventionDate: {
                                seconds: element.lastInterventionDate._seconds ?? element.lastInterventionDate.seconds,
                                nanoseconds: element.lastInterventionDate._nanoseconds ?? element.lastInterventionDate.nanoseconds
                            },
                            caducidadMeses: element.caducidadMeses,
                            id: element.id,
                            caducidadKm: element.caducidadKm
                        }

                        if (isPiece(element.id)) {
                            item['price(euro)'] = element['price(euro)'] ?? 0

                        }

                        return item;
                    });

                return map_parts;

            } else {
                return [];
            }
        }
    }



    const isPiece = (_id) => {
        const isPiece = PiezasOptionsFieldsArray.find(x => x !== undefined && x.id === _id)?.piece;
        return isPiece;
    }

    return {
        InitVehicleMapper, InterventionsMapper, PartsMapper
    }

}


const RevisionsEntity = {
    name: ""
    , status: ""
};

const PartsEntity = {
    piece: false || true
    , name: ""
    , status: ""
    , lastInterventionDate: ""
    , id: ""
    , caducidadKm: ""
    , caducidadMeses: ""
    , isReplaced: ""
};

const InterventionsEntity = {
    comments: "",
    confirmed: false,
    date: Timestamp.date,
    id: "",
    imageUrl: "",
    pieces: [],
    revisions: [],
    vehicleKm: "",
    vehiclePlate: "",
    workshopId: ""
}


const VehicleEntity = {
    buyDate: "",
    emissions: "",
    energy: "",
    interventions: [],
    km: "",
    kmPerYear: "",
    lastItv: "",
    manufacturer: "",
    model: "",
    parts: [],
    plate: "",
    safescore: "",
    techSpec1: "",
    techSpec2: "",
    userIdOwner: "",
    userIdRepairShop: "",
    version: "",
    vin: "",
    workshop: {
        driviId: "",
        placeId: ""
    },
    year: ""
}

export { VehicleMapper, VehicleEntity, InterventionsEntity, PartsEntity, RevisionsEntity }