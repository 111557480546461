import { Button, Input, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToast } from '../../../components/toastComponent/toast.Actions';
import { recoverAccount } from '../../../services/API/User.Routes';

const RecoverAccountComponent = () => {

  // HOOKS

    const { openCustomToast } = useToast()
    const { register, handleSubmit } = useForm()

  // STATES
  
    const [loadingState ] = useState(false);
    const [disableButtonState ] = useState(false);
    const [confirmedState, setConfirmedState] = useState(false);
    const [emailState, setEmailState] = useState("");

  // FUNCTIONS
  
    const onSubmit = async (data) => {
       const response = await recoverAccount(emailState);

       if (response.data.type === "success") {
        setConfirmedState(true);
       } else {
        openCustomToast("Oops! No hemos encontrado este correo", "error");
       }
    };
  
//RENDER

    return (
      <>
        {!confirmedState ? (
          <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
              <img style={{ height: "35px" }} src="/img/drivi_logo.svg" alt="" />
              <Typography marginTop={"20px"} fontWeight={"300"} variant="subtitle1">
                Introduce tu correo electrónico para recuperar tu cuenta
              </Typography>
            </Stack>
            <Stack gap={"20px"} sx={{ width: "fit-content", justifyContent: "center" }}>
              <form style={{ padding: "20px", width: "fit-content", display: "flex", flexDirection: "column", gap: "10px" }} onSubmit={handleSubmit(onSubmit)}>
                <Input {...register("password-confirm")} value={emailState} onChange={(e) => setEmailState(e.target.value)} id="standard-adornment-password" type={"email"} label="Email" placeholder="Email" />
                <Button
                  disabled={disableButtonState}
                  style={{ backgroundColor: disableButtonState ? "grey" : "primary.main", color: "white", marginTop: "0px", borderRadius: "0" }}
                  width={"100%"}
                  type="submit"
                  variant="contained"
                >
                  Enviar correo de recuperación
                </Button>
                <Typography textAlign={"center"} color={"red"}>
                  {loadingState && displayErrorMessage()}
                </Typography>
              </form>
            </Stack>
          </Stack>
        ) : (
          <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
              <img style={{ height: "35px" }} src="/img/drivi_logo.svg" alt="" />
              <Typography marginTop={"20px"} fontWeight={"300"} variant="subtitle1">
                Revisa tu correo electrónico para recuperar tu cuenta
              </Typography>
            </Stack>
          </Stack>
        )}{" "}
      </>
    );
  }

export default RecoverAccountComponent