import * as React from "react";
import { useState } from "react";

import { useAtom } from "jotai";
import { userVehicleAtom, vehicleInterventionAtom } from "../../../../context/atoms/Atoms.Storage";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Container, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import { useEffect } from "react";
import { PartsEntity } from "../../../../context/mappers/Vehicle.Mapper";
import SetInterventionPartValue from "../actions/SetInterventionPartValue";
import ChangedItemsItem from "../changedItems/ChangedItems.Item";

const useStyles = makeStyles({
  root: {
    '& span[class*="MuiTypography-root"]': {
      fontWeight: "bold",
      border: "1px solid #c6c8db",
      borderRadius: "0.2em",
      padding: "0.1em 0.2em 0 0.2em",
      background: "#f6f5ff",
    },
    background: "#f6f5ff",
  },
});

export default function Revisions({ carcomponents, interventions }, _props) {
  const { setValues } = SetInterventionPartValue();
  const classes = useStyles();
  const [vehicleInterventionState, SetvehicleInterventionState] = useAtom(vehicleInterventionAtom);
  const [userVehicleState, setUserVehicleState] = useAtom(userVehicleAtom);

  const [stateMap, setStateMap] = useState({
    radio_Pastillas_freno_delanteras: PartsEntity,
    radio_Pastillas_freno_traseras: PartsEntity,
    radio_Discos_freno_trasero: PartsEntity,
    radio_Presion_alineamiento_neumaticos: PartsEntity,
    radio_Revision_neumaticos: PartsEntity,
    radio_Suspension: PartsEntity,
    radio_Embrague: PartsEntity,
    radio_Liquido_de_frenos: PartsEntity,
    radio_Luces: PartsEntity,
    radio_Bateria: PartsEntity,
    radio_Liquido_limpiaparabrisas: PartsEntity,
    radio_Liquido_refrigerante: PartsEntity,
    radio_Direccion: PartsEntity,
    radio_Climatizacion: PartsEntity,
  });

  let partsAlreadyReaded = [];


  useEffect(() => {

    if (vehicleInterventionState) {

      carcomponents.filter((x) => x !== undefined && x.piece === false)
        .forEach((data) => {

          if (data) {
            if (partsAlreadyReaded.findIndex((x) => x !== undefined && x.id === data.id) === -1) {
              partsAlreadyReaded.push(data.id);

              new Promise(async (resolve, reject) => {
                const result = await findComponentByName(data.id, data.fields[0].defaultValues.status);

                resolve(result);

              }).then((data) => {

                data.forEach((pieza) => {

                  pieza.fields.forEach((field) => {
                    const idValue = pieza.id + "";
                    const labelValue = pieza.label;

                    field.action = (event, status, isReplaced, id, name, getState, setState) => handleRadioChange(event, status, isReplaced, idValue, labelValue, `radio_${pieza.id}`, `setRadio_${pieza.id}`);
                  });
                });

              });
            }
          }

        });
    }
  }, [vehicleInterventionState]);


  const findComponentByName = async (_id, _status) => {

    const result = carcomponents.map((component) => {
      if (_id === component.id) {
        if (component.fields) {
          component.fields.forEach((field) => {
            if (field.action) {
              field.action(null, _status, null, field.id, field.label, "radio_" + field.id, "setRadio_" + field.id);
            }
          });
        }
      }

      return component;
    });

    return result;
  };

  const handleRadioChange = (event, _status, _isReplaced, _id, name, getState, setState) => {
    const data_values = { status: _status, value_checkbox: _isReplaced };
    setValues(event, false, data_values, _id, name, getState, stateMap, setStateMap);
  };

  const setActionFields = () => {
    if (carcomponents) {
      carcomponents.forEach((pieza) => {
        pieza.fields.forEach((field) => {
          const idValue = pieza.id;
          const labelValue = pieza.label;

          field.action = (event, status, isReplaced, id, name, getState, setState) => handleRadioChange(event, status, isReplaced, idValue, labelValue, `radio_${pieza.id}`, `setRadio_${pieza.id}`);
        });
      });

      const renderedComponents = carcomponents?.sort((a, b) => {
        const getPriority = (element) => {
          if (element.label.includes('Neumáticos')) return 1; 
          if (element.label.includes('Pastillas')) return 2;
          if (element.label.includes('Discos')) return 3;
          return 4;
        };
    
        const priorityA = getPriority(a);
        const priorityB = getPriority(b);
    
        return priorityA - priorityB;
      }).map((element) => {
        return <ChangedItemsItem key={`changedItemsItem_${element.id}`} id={element.id} item={element}></ChangedItemsItem>;
      });

      return renderedComponents;
    }
    return [];
  };

  return (
    <React.Fragment>
      <Container width="100%" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" textAlign={"center"} sx={{ width: "fit-content", maxWidth: "60%", color: "grey", fontSize: "1.15em", color: "#8f8f8f", fontWeight: "1em", align: "left" }}>
          Selecciona el estado de los elementos del vehículo que has podido revisar.
        </Typography>
      </Container>
      <Stack sx={{ marginTop: 4, marginLeft: "-1em", padding: { lg: "0 1em 0 1em" }, flexDirection: "row", justifyContent: "space-between", alignItems: "space-between", flexWrap: "wrap" }}>
        {setActionFields().map((x) => {
          return x;
        })}
      </Stack>
    </React.Fragment>
  );
}
