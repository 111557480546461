import { ApiCall_GET, ApiCall_POST, ApiCall_PATCH } from "..";

export const GetPiecesMain = async (_token) => {

  const response = await ApiCall_GET("/api/getallusers", _token);
  return response;

}

export const ValidateToken = async (_token) => {

  if (_token) {
    const response = await ApiCall_GET("/api/verificatoken", _token);
    return response;
  }
}

//Calendar

export const getCalendarData = async (_token, _year, _clientId) => {
  const response = await ApiCall_GET(`/api/getcalendar?year=${_year}&userId=${_clientId}`, _token);
  return response;
}

export const GetAllAppointedBilling = async (_token, _clientId) => {

  const response = await ApiCall_GET(`/api/getallappointedbilling?clientId=${_clientId}`, _token);
  return response;
}

//Vehicles

export const GetVehiclesByClient = async (_token, _clientId) => {
  const response = await ApiCall_GET(`/api/getvehiclesbyclient?clientId=${_clientId}`, _token);
  return response;
}

export const GetVehicleById = async (_token, _vehicleId) => {
  const response = await ApiCall_GET(`/api/getvehiclebyid?vehicleId=${_vehicleId}`, _token);
  return response;
}

//Users

export const GetUsersByClient = async (_token, _clientId) => {
  const response = await ApiCall_GET(`/api/getusersbyclient?clientId=${_clientId}`, _token);
  return response;
}

export const GetUser = async (_token, _userId) => {
  const response = await ApiCall_GET(`/api/getuser?userId=${_userId}`, _token);
  return response;
}

export const UpdateUser = async (_token, _userId, _body) => {
  const response = await ApiCall_PATCH(`/api/updateuserdetails?userId=${_userId}`, _body, _token);
  return response;
}

export const APILogin = async (_email, _password) => {
  const response = await ApiCall_POST("/api/login", { email: _email, password: _password });
  return response;
}

export const GetUserByEmail = async (_token, _email) => {
  const response = await ApiCall_GET(`/api/getuserbyemail?email=${_email}`, _token);
  return response;
}

// Postgre

export const getAllManufacturers = async (_token) => {
  const response = await ApiCall_GET("/api/getallmanufacturers", _token);
  return response;
}

export const getByIdManufacturer = async (_token, _manufacturerId) => {
  const response = await ApiCall_GET(`/api/getByIdManufacturer?id_manufacturer=${_manufacturerId}`, _token);
  return response;
}

export const getAllCarcomponents = async (_token) => {
  const response = await ApiCall_GET("/api/getallcarcomponents", _token);
  return response;
}

export const createManualIntervention = async (_token, _interventionData) => {
  const response = await ApiCall_POST("/api/createmanualintervention", _interventionData, _token);
  return response;
}

// export const getByRepairshopIdVehicles = async (_token, _clientId) => {
//   const response = await ApiCall_GET(`/api/getbyrepairshopidvehicles?id_client=${_clientId}`, _token);
//   return response;
// }

export const getAllByRepairshopIdUsers = async (_token) => {
  const response = await ApiCall_GET(`/api/getAllByRepairshopIdUsers?`, _token);
  return response;
}

export const confirmIntervention = async (_token, _interventionData) => {
  const response = await ApiCall_PATCH("/api/confirmintervention", _interventionData, _token);
  return
  // return response;
}

export const getAllModels = async (_token) => {
  const response = await ApiCall_GET("/api/getallmodels", _token);
  return response;
}

export const getInterventionsByVehicleId = async (_token, _vehicleId) => {
  const response = await ApiCall_GET(`/api/getinterventionsbyvehicleid?id_vehicle=${_vehicleId}`, _token);
  return response;
}


export const setUserOwnerMigration = async (_token) => {
  const response = await ApiCall_POST(`/migration/userOwnerMigration?`, _token);
  return response;
}
