import React from 'react';
import ModalComponent from '../Modal.Component';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const OuterStack = styled(Stack)(() => ({
  height: "fit-content",
  maxHeight: "90%",
  width: "fit-content",
  padding: "20px",
  paddingTop: "40px",
  backgroundColor: "white",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
}));

const InnerStack = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  gap: "10px",
  padding: "0px",
}));

const CustomTypography = styled(Typography)(() => ({
  padding: "10px",
  textTransform: "uppercase",
  fontWeight: "300",
}));

const CustomButton = styled(Button)(() => ({
  width: "50%",
}));

const ConfirmModalComponent = ({ confirmText, handleConfirm, handleCancel }) => {
  return (
    <ModalComponent>
      <OuterStack>
        <InnerStack>
          <CustomTypography>{confirmText}</CustomTypography>
          <CustomButton onClick={handleConfirm} variant="contained">
            Sí
          </CustomButton>
          <CustomButton onClick={handleCancel}>Volver</CustomButton>
        </InnerStack>
      </OuterStack>
    </ModalComponent>
  );
};

export default ConfirmModalComponent;
