import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, Stack } from "@mui/material";
import { useState } from "react";
import { changedItemsAtom, fieldsMapAtom, partsIsSelectedAtom, reviewsAtom, urlParam } from "../../../../context/atoms/Atoms.Storage";
import { useAtom } from "jotai";
import { CapitalizeFirstLetter } from "../../../../utils/FormatValues";
import { FieldType } from "../../../../enums";
import EditStates from "../../../../context/EditStates";
import SetInterventionPartValue from "../actions/SetInterventionPartValue";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useRef } from "react";
import { useEffect } from "react";

const expirationField_style = (_value) => {
  return {
    width: "85%",
    "& input": {
      height: "0.5em",
      width: "250px",
      fontSize: "1.5em",
      backgroundColor: _value ? "#e1dfed" : "#ecebf1",
      borderRadius: "0.2em",
      padding: "0.8em 0 0.8em 0.5em ",
      textAlign: "center",
      webkitAppearance: "none",
      "::-webkit-inner-spin-button": {
        webkitAppearance: "none",
        mozappearance: "none",
      },
      "::-webkit-outer-spin-button": {
        webkitAppearance: "none",
        mozappearance: "none",

      },
    },
  };
};

const radio_colors_style = [
  { key: 0, value: "#4abe48" },
  { key: 1, value: "#fd8d03" },
  { key: 2, value: "#e73d3d" },
];

const radio_styles = (_value, _isSelected) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    background: "red",
    borderRadius: " 0.3em",
    maxWidth: "64px",
    background: _isSelected && _value < 3 ? radio_colors_style[_value]?.value : "",
    color: _isSelected && _value < 3 ? "#ffffff" : "#333",
    "& .Mui-checked": {
      color: _isSelected && _value < 3 ? "#ffffff" : "#333",
    },
    "& span": {
      fontSize: "0.8em",
    },
  };
};

export default function ChangedItemsItem(_props) {
  const Item = _props.item;

  const { insertValueStateArray, updateObjectById } = EditStates();
  const { setValues } = SetInterventionPartValue();

  const [fieldsMapState, setFieldsMapState] = useAtom(fieldsMapAtom);
  const [changedItemsState, setChangedItemsState] = useAtom(changedItemsAtom);
  const [reviewsState, setReviewsState] = useAtom(reviewsAtom);
  const [partsIsSelectedState, setPartsIsSelectedState] = useAtom(partsIsSelectedAtom);
  const [urlParamState] = useAtom(urlParam);

  const [isVisibleState, setIsVisibleState] = useState(false);
  const isMounted = useRef([]);

  useEffect(() => {

    if (isMounted) {

      if (_props.selected) {

        if (isMounted.current.filter(x => x === Item.id).length === 0) {

          changeItemSelectedStatus(Item, Item.label, Item.piece);
          isMounted.current.push(Item.id);
        }
      }
    }


  }, []);


  const getFieldValue = (_fieldType, _id, _textFieldElements) => {
    let stateValue;
    let fieldValue;

    if (_fieldType === FieldType.MONTH) {
      stateValue = changedItemsState.find((item) => item !== undefined && item.id === _id)?.caducidadMeses;
      fieldValue = stateValue ? stateValue : _textFieldElements.defaultValues?.caducidadMeses;
    } else if (_fieldType === FieldType.KM) {
      stateValue = changedItemsState.find((item) => item !== undefined && item.id === _id)?.caducidadKm;
      fieldValue = stateValue ? stateValue : _textFieldElements.defaultValues?.caducidadKm;
    } else if (_fieldType === FieldType.STATUS) {
      stateValue = reviewsState.find((x) => x !== undefined && x.id === _id)?.status;
      fieldValue = stateValue >= 0 ? stateValue : _textFieldElements.defaultValues?.status;
    } else if (_fieldType === FieldType.REPLACED) {
      if (reviewsState.find((x) => x !== undefined && x.id === _id)) {
        stateValue = reviewsState.find((x) => x !== undefined && x.id === _id)?.isReplaced;
      } else {
        stateValue = changedItemsState.find((x) => x !== undefined && x.id === _id)?.isReplaced;
      }
      fieldValue = stateValue >= 0 ? stateValue : _textFieldElements.defaultValues?.isReplaced;
    }

    if ((_fieldType !== FieldType.STATUS && stateValue) || (_fieldType === FieldType.STATUS && fieldValue >= 0 && fieldValue < 3)) {
      let existingItem = partsIsSelectedState?.find((x) => x !== undefined && x.id === _id);

      if (!existingItem) {
        insertValueStateArray({ id: _id, value: true }, setPartsIsSelectedState);
      }
    } else if (_fieldType === FieldType.STATUS && fieldValue === 3) {
      const itemToRemoveIndex = partsIsSelectedState?.findIndex((x) => x !== undefined && x.id === _textFieldElements.id);

      if (itemToRemoveIndex !== -1) {
        partsIsSelectedState.splice(itemToRemoveIndex, 1);
        setPartsIsSelectedState(partsIsSelectedState);
      }
    }

    return fieldValue;
  };


  const changeItemSelectedStatus = (_element, _label, _isPiece) => {

    let existingItem = partsIsSelectedState?.find((x) => x !== undefined && x.id === _element.id);
    let selectedStatus;

    if (_isPiece) {

      if (!existingItem) {
        selectedStatus = true;
        insertValueStateArray({ id: _element.id, value: selectedStatus }, setPartsIsSelectedState);
      } else {
        selectedStatus = !existingItem.value;
        let editedExistingItem = updateObjectById(_element.id, { value: selectedStatus }, partsIsSelectedState);
        setPartsIsSelectedState(editedExistingItem);
      }

      if (selectedStatus !== undefined) {
        if (selectedStatus === true) {

          const existingItem = changedItemsState.find((x) => x !== undefined && x.id === _element.id);

          if (!existingItem) {

            const value_months = getFieldValue(FieldType.MONTH, _element.id, _element) || _element.fields[0].defaultValues?.caducidadMeses;;
            const value_kms = getFieldValue(FieldType.KM, _element.id, _element) || _element.fields[0].defaultValues?.caducidadKm;
            const value_checkbox = getFieldValue(FieldType.REPLACED, _element.id, _element);
            const data_values = { value_months: value_months, value_kms: value_kms, value_checkbox: value_checkbox };

            setValues(null, true, data_values, _element.id, _label, `TextChange_${_element.id}`, fieldsMapState, setFieldsMapState);

          }
        } else if (selectedStatus === false) {

          const existingItem = changedItemsState.find((x) => x.id === _element.id);
          if (existingItem) {
            setChangedItemsState(changedItemsState.filter((x) => x !== undefined && x.id !== existingItem.id));
          }

        }
      }
    }
  };

  const partIsSeletected = (_id) => {
    return partsIsSelectedState.find((x) => x !== undefined && x.id === _id)?.value;
  };

  return (
    <Grid
      item

      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "1em ",
      }}
    >
      {Item.fields.map((textFieldElements) => {
        let id_field_month = textFieldElements.id + "_Meses";
        let id_field_kilometros = textFieldElements.id + "_Kilometros";
        let label_field_kilometros = textFieldElements.label + " Kilometros";

        return (
          <Grid
            item
            id={"id_changedItems_item"}
            xs={8}
            onMouseOver={() => setIsVisibleState(true)}
            onMouseOut={() => setIsVisibleState(false)}
            sx={{
              position: "relative",
              height: "auto",
              maxHeight: "16em",
              display: "flex",
              maxWidth: "20em!important",
              flexDirection: "column",
              alignContent: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f4f3fb",
              borderRadius: "0.5em",
              padding: "1em 0 0 0",
              border: isVisibleState || partIsSeletected(textFieldElements.id) ? (Item?.piece ? "3px solid red" : "#b0b4c2 3px solid") : "3px solid transparent",
              transition: "0.3s",
              boxShadow: "1px 4px 20px 0 rgb(0 0 0 / 20%)",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {partIsSeletected(textFieldElements.id) && !Item?.piece && (
              // <Grid sx={{ width: "fit-content", gap: "50px", flexWrap: "nowrap", position: "absolute", top: "100px", left: "10px", justifyContent: "center", alignItems: "center" }}>
              //   <Typography sx={{ textAlign: "center", fontSize: "0.9em" }}>Cambio</Typography>
              //   <Checkbox
              //     sx={{ height: "fit-content", '& .MuiSvgIcon-root': { color: '#ee4136' }, }}
              //     onClick={(event) => event.stopPropagation()}
              //     onChange={(event) => textFieldElements.action(event, "###", "###", event.target.value, id_field_month, Item.label)}
              //     value={getFieldValue(FieldType.REPLACED, textFieldElements.id, textFieldElements)}
              //   />
              // </Grid>
              <div></div>
            )}

            <Typography
              variant="h8"
              sx={{
                textAlign: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "95%",
                textWrap: "nowrap",
                minHeight: "1.5em",
                fontWeight: partIsSeletected(textFieldElements.id) ? "bold" : "",
              }}
              onClick={() => changeItemSelectedStatus(textFieldElements, Item.label, Item.piece)}
            >
              {CapitalizeFirstLetter(Item.label)}
            </Typography>
            <Box>
              <Grid
                key={"key_changedItemsItem_" + textFieldElements.id}
                container
                xs={12}
                spacing={0}
                alignItems="left"
                sx={{
                  padding: "1em 0 0 0",
                }}
                onClick={() => changeItemSelectedStatus(textFieldElements, Item.label, Item.piece)}
              >
                {Item.piece === true && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        position: "relative",
                        height: "6em",
                        margin: "1em 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "& img": {
                          maxHeight: "90%",
                          maxWidth: "90%",
                        },
                      }}
                    >
                      <img src={`/img/${partIsSeletected(textFieldElements.id) ? textFieldElements.img + "_on" : textFieldElements.img}.svg`} />
                    </Grid>

                    <Grid container xs={12} sx={{ height: "5em", padding: "0 0 0 0.3em" }}>
                      <Grid item xs={4} sx={{ visibility: partIsSeletected(textFieldElements.id) ? "visible" : "hidden" }}>
                        <TextField
                          name={`txt_caducidadMeses_${textFieldElements.id}`}
                          sx={expirationField_style(textFieldElements.value?.caducidadMeses)}
                          id={id_field_month}
                          type="tel"
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => textFieldElements.action(event, event.target.value, "###", "###", id_field_month, Item.label)}
                          value={getFieldValue(FieldType.MONTH, textFieldElements.id, textFieldElements)}
                        />
                        <Typography xs={12} sx={{ textAlign: "center", fontSize: "0.9em" }}>
                          Meses
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sx={{ visibility: partIsSeletected(textFieldElements.id) ? "visible" : "hidden" }}>
                        <TextField
                          onClick={(event) => event.stopPropagation()}
                          name={`txt_caducidadKms_${textFieldElements.id}`}
                          sx={expirationField_style(textFieldElements.value?.caducidadKm)}
                          id={id_field_kilometros}
                          type="tel"
                          onChange={(event) => textFieldElements.action(event, "###", event.target.value, "###", id_field_kilometros, label_field_kilometros)}
                          value={getFieldValue(FieldType.KM, textFieldElements.id, textFieldElements)}
                        />
                        <Typography xs={12} sx={{ textAlign: "center", fontSize: "0.9em" }}>
                          Kilómetros
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {Item.piece === false && (
                  <Grid container xs={12} sx={{ width: "300px", height: "90px", padding: "0 0 0.2em 0.3em" }}>
                    <Grid
                      sx={{
                        width: "fit-content",
                        gap: "50px",
                        flexWrap: "nowrap",
                        position: "absolute",
                        bottom: "-25px",
                        left: "0",
                        right: "0",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      {/* <Checkbox
                  sx={{ height: "fit-content", "& .MuiSvgIcon-root": { color: "#ee4136" } }}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event) => textFieldElements.action(event, "###", "###", event.target.value, id_field_month, Item.label)}
                  value={getFieldValue(FieldType.REPLACED, textFieldElements.id, textFieldElements)}
                /> */}
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          textFieldElements.action(event, 0, textFieldElements.status, textFieldElements.label);
                          textFieldElements.status = 0
                          textFieldElements.action(event, "###", !textFieldElements.isReplaced, "###", id_field_month, Item.label);
                          textFieldElements.isReplaced = !textFieldElements.isReplaced
                        }}
                      >
                        <ChangeCircleIcon
                          sx={{
                            transition: "all 0.5s",
                            color: textFieldElements.isReplaced ? "#4abe48" : "secondary.main",
                            rotate: textFieldElements.isReplaced ? "360deg" : "0",
                            height: "40px",
                            width: "40px",
                            backgroundColor: "white",
                            borderRadius: "100%",
                            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      </IconButton>
                    </Grid>
                    <RadioGroup
                      value={getFieldValue(FieldType.STATUS, textFieldElements.id, textFieldElements) ?? 3}
                      onChange={(event) => {
                        textFieldElements.isReplaced = false
                        textFieldElements.action(event, event.target.value, textFieldElements.status, textFieldElements.label);
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          {!textFieldElements.isReplaced && (
                            <>
                              <FormControlLabel
                                xs={6}
                                value="0"
                                control={<Radio />}
                                label={"Óptimo"}
                                sx={radio_styles(
                                  0,
                                  reviewsState.find((x) => x !== undefined && x.id === textFieldElements.id && x.status === 0)
                                )}
                              />
                              <FormControlLabel
                                xs={6}
                                value="1"
                                control={<Radio />}
                                label={"Regular"}
                                sx={radio_styles(
                                  1,
                                  reviewsState.find((x) => x !== undefined && x.id === textFieldElements.id && x.status === 1)
                                )}
                              />
                              <FormControlLabel
                                xs={6}
                                value="2"
                                control={<Radio />}
                                label={"Deficiente"}
                                sx={radio_styles(
                                  2,
                                  reviewsState.find((x) => x !== undefined && x.id === textFieldElements.id && x.status === 2)
                                )}
                              />
                              <FormControlLabel
                                xs={6}
                                value="3"
                                control={<Radio />}
                                label={"No rev."}
                                sx={radio_styles(
                                  3,
                                  reviewsState.find((x) => x !== undefined && x.id === textFieldElements.id && x.status === 3)
                                )}
                              />
                            </>
                          )}
                        </Stack>
                        {textFieldElements.isReplaced && (
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              marginRight: "5px",
                              backgroundColor: "#4abe48",
                              color: "white",
                              textAlign: "left",
                              fontSize: "0.9em",
                              width: "265px",
                              height: "60px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Reemplazado
                          </Typography>
                        )}
                      </Box>
                    </RadioGroup>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
