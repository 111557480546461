export default function EditStates() {

    const insertValueStateArray = (_data, _setState) => {
        _setState(prevState => [...prevState, _data])
    }

    const updateObjectById = (_id, _newItem, _state) => {
        const updatedData = _state.map(item => {
            if (item.id === _id) {
                item = _newItem;
            }
            return item;
        });

        return updatedData;
    }

    return { insertValueStateArray, updateObjectById };

}