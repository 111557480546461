import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const BubbleChart = ({ reviews }) => {
  const chartRef = useRef(null);
  const width = 500;
  const height = 500;

  // Function to select color based on rating
  const selectColor = (rating) => {
    switch (rating) {
      case 1:
        return "red";
      case 2:
        return "orange";
      case 3:
        return "yellow";
      case 4:
        return "green";
      case 5:
        return "lightgreen";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    if (!reviews || reviews.length === 0) return;

    // Prepare the data for D3
    const data = reviews.map((review) => ({
      r: review.rating * 5, // Bubble size based on rating
      color: selectColor(review.rating),
      text: review.author_name || "N/A", // Text inside the bubble
      reviewText: review.text || "N/A",
    }));

    // Create a D3 force simulation
    const simulation = d3.forceSimulation()
      .force("x", d3.forceX(width / 2).strength(0.05))
      .force("y", d3.forceY(height / 3).strength(0.05))
      .force("collide", d3.forceCollide().radius((d) => d.r + 1).iterations(3));

    // Select the chart area and append the SVG element
    const svg = d3.select(chartRef.current)
      .html("") // Clear any previous SVGs
      .append("svg")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("style", "width: 100%; height: auto; height: intrinsic;");

    // Add circles (bubbles)
    const node = svg.selectAll("circle")
      .data(data)
      .join("circle")
      .attr("r", (d) => d.r)
      .attr("fill", (d) => d.color)
      .style("pointer-events", "auto")
      .style("cursor", "pointer") 

      .on("click", (event, d) => {
        console.log(`Bubble clicked: ${d.text}`);
      })
      .call(drag(simulation));

    // Add text inside bubbles
    const label = svg.selectAll("text")
      .data(data)
      .join("text")
      .attr("text-anchor", "middle")
      .attr("dy", ".35em")
      .text((d) => d.text.split(" ")[0])
      .style("pointer-events", "none")
      .style("font-size", (d) => d.r / 3 + "px")
      .style("font-weight", "300")
      .style("font-family", "Inter, sans-serif")	
      .call(drag(simulation));

    // Update circle and text positions on each tick of the simulation
    simulation.nodes(data).on("tick", () => {
      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      label.attr("x", (d) => d.x).attr("y", (d) => d.y);
    });

    // Drag behavior function
    function drag(simulation) {
      return d3.drag()
        .on("start", (event, d) => {
          if (!event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        })
        .on("drag", (event, d) => {
          d.fx = event.x;
          d.fy = event.y;
        })
        .on("end", (event, d) => {
          if (!event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        });
    }


    // Cleanup SVG on component unmount
    return () => {
      svg.remove();
    };
  }, [reviews]); // Add reviews as a dependency to re-render when it changes

  return (
    <div        style={{
      top: "0%",
      left: "0%",
      position: "fixed",
      width: "100vw",
      height: "90vh",
      pointerEvents: "none", // Allow clicks to pass through the background
    }}>
      <div ref={chartRef}></div>
    </div>
  );
};

export default BubbleChart;
