import React from 'react'
import { Container} from '@mui/material'
import { CircularProgress } from "@mui/material"
import MainContent from './MainContent.component';

const LoadingComponent = () => {
    return (
      <MainContent>
        <Container sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress style={{ color: "primary.main" }} />
        </Container>
      </MainContent>
    );
}

export default LoadingComponent