import React from 'react'
import { Container, Typography } from '@mui/material'

const HeaderComponent = ({ text, icon }) => {

    return (
      <Container sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", borderRadius: "20px", width: "fit-content" }}>
        {icon}
        <Typography variant="h3" color={"#e94e1b"} p={"15px"} fontWeight={"100"}>
          {text}
        </Typography>
      </Container>
    );
}

export default HeaderComponent