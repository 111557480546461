import React, { useEffect, useState } from 'react'
import MainContent from '../../components/MainContent.component.jsx'
import HeaderComponent from '../../components/Header.Component'
import AssigmentIcon from '@mui/icons-material/Assignment.js';
import { getByRepairshopIdInterventions } from '../../services/API/Intervention.Routes.js';
import { getStorageObject } from '../../utils/LocalStorage.js';
import { Button, Container, Stack, Typography } from '@mui/material';
import DataTable from './components/DataTable.jsx';
import LoadingComponent from '../../components/Loading.Component.jsx';
import ConfirmEditModal from './components/ConfirmEditModal.jsx';
import { demoAtom, interventionEditModeAtom } from '../../context/atoms/Atoms.Storage.jsx';
import { useAtom } from 'jotai';
import interventionDemoData from '../../utils/demoData/interventionData.json'
import { useNavigate } from 'react-router-dom';

const Interventions = () => {

  //HOOKS

  const navigate = useNavigate();

  //STATES

  const [interventionsState, setInterventionsState] = useState();
  const [interventionDataState, setInterventionDataState] = useState();
  const [loadingState, setLoadingState] = useState(true);
  const [editModalState, setEditModalState] = useState(false);
  const [interventionEditModeState, setInterventionEditModeState] = useAtom(interventionEditModeAtom);
  const [searchValueState, setSearchValueState] = useState();
  const [filteredInterventionsState, setFilteredInterventionsState] = useState();
  const [ demo ] = useAtom(demoAtom);

  //USE EFFECTS

  useEffect(() => {
    if (demo === true) {
      setInterventionsState(interventionDemoData);
      setFilteredInterventionsState(interventionDemoData)
      setLoadingState(false);
    } else if (demo === false) {

      const repairshopId = getStorageObject("repairShopId");
      const token = getStorageObject("token");

      getByRepairshopIdInterventions(token, repairshopId)
        .then(({ data: interventionsData, status, ok }) => {
          setLoadingState(false);
          if (interventionsData && interventionsData.length > 0) {
            setInterventionsState(interventionsData)
            setFilteredInterventionsState(interventionsData)
          }
        }).catch(error => { });

    }
  }, [demo]);

  useEffect(() => {
    if (searchValueState) {
      const filteredInterventionsState = interventionsState.filter((intervention) => JSON.stringify(intervention).toLocaleLowerCase().includes(searchValueState.toLowerCase()));
      setFilteredInterventionsState(filteredInterventionsState);
    }
  }, [searchValueState]);

  //HTML

  if (loadingState) {
    return <LoadingComponent />
  }

  return (
    <MainContent>
      <HeaderComponent text={"Intervenciones"} icon={<AssigmentIcon sx={{ color: "#e94e1b", height: "40px", width: "40px" }} />} />

      <Container sx={{ padding: "0px 10px 10px 10px", paddingTop: "10px" }}>
        {interventionsState && interventionsState.length > 0 ? (
          <DataTable
            demo={demo}
            setInterventionData={setInterventionDataState}
            editModal={editModalState}
            setEditModal={setEditModalState}
            searchValue={searchValueState}
            setSearchValue={setSearchValueState}
            interventions={searchValueState ? filteredInterventionsState : interventionsState}
          />
        ) : (
          <Stack sx={{ justifyContent: "center", alignItems: "center", maxWidth: "40em", margin: "auto" }}>
            <Typography sx={{ textAlign: "center", fontWeight: "100" }} variant="h2">
              No hay intervenciones registradas
            </Typography>
            <Typography sx={{ fontSize: "1.5rem", textAlign: "center", fontWeight: "200", padding: "1em 0 0.5em 0", color: "#6f6f6f" }}>De momento.</Typography>
            <Button
              sx={{ marginTop: "20px" }}
              variant="contained"
              onClick={() => {
                navigate("/intervencion/crear");
              }}
            >
              Crear intervención
            </Button>
          </Stack>
        )}
      </Container>
      {editModalState && <ConfirmEditModal setInterventionEditMode={setInterventionEditModeState} interventionData={interventionDataState} setEditModal={setEditModalState} />}
    </MainContent>
  );
}

export default Interventions