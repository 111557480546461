import React, { useEffect, useState } from 'react';
import { useToast } from '../../components/toastComponent/toast.Actions.jsx';
import { Button, CircularProgress, Container, FormControlLabel, IconButton, List, ListItem, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from "@mui/material";
import CarRepair from '@mui/icons-material/CarRepair';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { Timestamp } from 'firebase/firestore';
import StarIcon from '@mui/icons-material/Star';

import { emailValidator, phoneValidator, contactValidator, nameValidator, urlValidator } from "./components/utils/validators.js";

import LoadingComponent from '../../components/Loading.Component';
import MainContent from '../../components/MainContent.component';
import HeaderComponent from '../../components/Header.Component';
import { getStorageObject } from '../../utils/LocalStorage.js';
import { getByIdRepairshop, updateRepairshopDetails } from '../../services/API/User.Routes.js';
import { demoAtom, checkInterventionsAtom } from '../../context/atoms/Atoms.Storage.jsx';
import { useAtom } from 'jotai';
import ProfilePictureComponent from './components/ProfilePicture.Component.jsx';

import WorkshopData from '../../utils/demoData/workshopData.json'
import PromotionComponent from './components/promotion.Component.jsx';
import BubbleChart from './components/BubbleChart.jsx';

const WorkshopPage = () => {

  //HOOKS

  const { openCustomToast } = useToast();
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });


  //STATES AND VARIABLES

  const [workshopState, setWorkshopState] = useState();
  const [promoState, setPromoState] = useState("");
  const [ctaValueState, setCtaValueState] = useState("");
  const [loadingSaveState, setLoadingSaveState] = useState(false);
  const [responsiveState, setResponsiveState] = useState(false);
  const [selectedValueState, setSelectedValueState] = useState();
  const [loadingState, setLoadingState] = useState(true);
  const [demo, setDemo] = useAtom(demoAtom);
  const [checkInterventions, setCheckInterventions] = useAtom(checkInterventionsAtom);

  const [bubbleChartIsOpen, setBubbleChartIsOpen] = useState(false);


  const radioGroupDefaultValue = (workshop) => {
    if (workshop?.cta.includes("Llamada")) { return "Llamada"; }
    else if (workshop?.cta.includes("WhatsApp")) { return "WhatsApp"; }
    else if (workshop?.cta.includes("Email")) { return "Email"; }
    else if (workshop?.cta.includes("Otro")) { return "Otro"; }
    else { return "" }
  };

  //USEEFFECTS

  useEffect(() => {
    const handleResize = () => {
      setResponsiveState(window.innerWidth < 968);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (demo === true) {
      console.log(WorkshopData)
      setWorkshopState(WorkshopData);
      setSelectedValueState(radioGroupDefaultValue(WorkshopData));
      setCtaValueState(WorkshopData.cta.replace("Email", "").replace("Llamada", "").replace("WhatsApp", "").replace("Otro", ""));
      setLoadingState(false);

    } else if (demo === false) {
      const token = getStorageObject("token");
      const repairshop_id = getStorageObject("repairShopId");

      getByIdRepairshop(token, repairshop_id).then(({ data: workshopData, status, ok }) => {
        setWorkshopState(workshopData);
        setSelectedValueState(radioGroupDefaultValue(workshopData));
        setCtaValueState(workshopData.cta.replace("Email", "").replace("Llamada", "").replace("WhatsApp", "").replace("Otro", ""));
        setLoadingState(false);
      }).catch(error => { });
    }
  }, [demo]);

  //FUNCTIONS

  const handlePromoChange = (event) => {
    setPromoState(event.target.value);
  };

  const addPromotion = (promo) => {
    if (promo.trim() !== "") {
      setWorkshopState((prevWorkshop) => ({
        ...prevWorkshop,
        repairshop_promotions: prevWorkshop.repairshop_promotions ? [...prevWorkshop.repairshop_promotions, { name: promoState, date_expiration: new Date(Date.now()), date_start: new Date(Date.now()) }] : [{ name: promoState, date_expiration: new Date(Date.now()), date_start: new Date(Date.now()) }],
      }));
    }
    setPromoState("");
  };

  const deletePromotion = (index) => {

    setWorkshopState((prevWorkshop) => {
      const updatedPromotions = [...prevWorkshop.repairshop_promotions];
      updatedPromotions.splice(index, 1);
      return { ...prevWorkshop, repairshop_promotions: updatedPromotions };
    });
  };

  const handleRadioChange = (event) => {
    setCtaValueState("");
    if (event.target.value === selectedValueState) {

      setCtaValueState("");
      setSelectedValueState("");
    } else {
      setSelectedValueState(event.target.value);
    }
  };


  const onSubmit = (data) => {
    const token = getStorageObject('token');
    const id = getStorageObject("repairShopId");

    setLoadingSaveState(true);
    data.repairshop_promotions = workshopState.repairshop_promotions;
    data.cta = selectedValueState + ctaValueState;
    data.id = id;

    updateRepairshopDetails(token, data).then(() => {
      setLoadingSaveState(false);
      openCustomToast("Taller actualizado", "success", "right", "top");
      setWorkshopState(data);
      setCheckInterventions(!checkInterventions);
    });
  };

  const formatDate = (date) => {
    if (date) {
      const expireDate = new Date(date);
      const localDate = new Date(expireDate.getTime() - expireDate.getTimezoneOffset() * 60000);
      return localDate.toISOString().substring(0, 10);
    } else {
      return new Date().toISOString().substring(0, 10);
    }
  };

  const handlePromoDate = (e, promo, type) => {
    const date = new Date(e.target.value);
    const timestamp = Timestamp.fromDate(date);
    if (type === "start") {
      promo.date_start = timestamp.toDate();
    } else if (type === "expiration") {
    
      if (!promo.date_expiration || promo.date_expiration < promo.date_start) {
        promo.date_expiration = Timestamp.fromDate(Date.now());
      }
      else if (promo.date_expiration >= promo.date_start) {
        promo.date_expiration = timestamp.toDate();
      }
    }
  };

  //RENDER

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <MainContent>
      <HeaderComponent text={"Mi taller"} icon={<CarRepair style={{ color: "#e94e1b", height: "40px", width: "40px" }} />} />
      <ProfilePictureComponent />
      <Stack margin={"20px 0"} gap={"20px"} sx={{ width: responsiveState ? "90%" : "80%", maxWidth: "1000px" }}>
        {workshopState && (
          <form style={{ display: "flex", flexDirection: "column", gap: "10px" }} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={"row"} justifyContent={"space-between"} width={"100%"} gap={"10px"}>
              <Container sx={{ width: "48%", borderRadius: "0px", padding: "10px", border: !workshopState?.name ? "2px solid red" : "2px solid transparent" }}>
                <TextField
                  sx={{ width: "100%" }}
                  {...register("name", { validate: nameValidator })}
                  error={errors.name ? true : false}
                  helperText={errors.name?.message}
                  id="name"
                  label="Nombre del taller"
                  variant="standard"
                  defaultValue={workshopState?.name}
                />
              </Container>
              <Container sx={{ width: "48%", borderRadius: "0px", padding: "10px", border: !workshopState?.contact ? "2px solid red" : "2px solid transparent" }}>
                <TextField
                  sx={{ width: "100%" }}
                  {...register("contact", { validate: contactValidator })}
                  error={errors.contact ? true : false}
                  helperText={errors.contact?.message}
                  id="contact"
                  label="Jefe de taller"
                  variant="standard"
                  defaultValue={workshopState?.contact}
                />
              </Container>
            </Stack>
            {/* <Container sx={{ width: "100%", borderRadius: "0px", padding: "10px", border: !workshopState?.repairshop_google?.google_url ? "2px solid red" : "2px solid transparent" }}>
              <TextField sx={{ width: "100%" }} {...register("google_url")} id="googleMyBusiness" label="Google My Business" variant="standard" defaultValue={workshopState?.repairshop_google?.google_url} />
            </Container> */}
            <Container sx={{ width: "100%", borderRadius: "0px", padding: "10px", border: !workshopState?.phone_number ? "2px solid red" : "2px solid transparent" }}>
              <TextField
                sx={{ width: "100%" }}
                {...register("phone_number", { validate: phoneValidator })}
                error={errors.phone_number ? true : false}
                helperText={errors.phone_number?.message}
                id="numeroDeTelefono"
                label="Numero de teléfono"
                variant="standard"
                defaultValue={workshopState?.phone_number}
              />
            </Container>
            <Stack alignItems={"center"} justifyContent={"space-between"} gap={"10px"} p={"0"} width={"100%"} margin={"20px 0"} boxShadow={"0 0 20px 0 rgba(0, 0, 0, 0.2)"}>
              <Stack sx={{ marginTop: "10px" }} width={"100%"} alignItems={"center"} justifyContent={"center"} direction={"row"}>
                <TextField sx={{ width: "80%", marginBottom: "10px" }} id="promo" label="Promociones" variant="standard" value={promoState} onChange={handlePromoChange} />
                <IconButton onClick={() => addPromotion(promoState)}>
                  <AddIcon />
                </IconButton>
              </Stack>
              <List sx={{ width: "100%", padding: "0", display: "flex", flexDirection: "column", gap: "1px" }}>
                {workshopState?.repairshop_promotions?.map((promo, index) => (
                  <PromotionComponent workshopState={workshopState} deletePromotion={deletePromotion} key={index} promo={promo} formatDate={formatDate} handlePromoDate={handlePromoDate} />
                ))}
              </List>
            </Stack>
            {workshopState && (
              <>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedValueState}
                  onChange={handleRadioChange}
                  name="radio-buttons-group"
                  sx={{ padding: "20px", boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)" }}
                >
                  <Stack>
                    <Typography margin={"10px 0"} color={"grey"} sx={{ textTransform: "uppercase", textAlign: "center", fontWeight: "250" }}>
                      ¿Cómo quieres que el cliente te pida cita?
                    </Typography>
                    <Stack paddingLeft={"50px"}>
                      <Tooltip arrow placement="left" title={`Si el WhatsApp de contacto es distinto a ${workshopState?.phone_number}, introdúcelo aquí`}>
                        <Stack style={{ width: "90%" }} height={"50px"} alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                          <FormControlLabel value="WhatsApp" control={<Radio onClick={handleRadioChange} />} label="WhatsApp" />
                          {selectedValueState === "WhatsApp" && (
                            <TextField
                              sx={{ marginBottom: "15px", width: "calc(100% - 100px)" }}
                              {...register("cta")}
                              value={ctaValueState}
                              onChange={(e) => setCtaValueState(e.target.value)}
                              helperText={errors.cta?.message}
                              id="whatsappContactPhone"
                              label={workshopState?.phone_number}
                              variant="standard"
                              required={false}
                            />
                          )}
                        </Stack>
                      </Tooltip>
                      <Tooltip arrow placement="left" title={`Si el teléfono de contacto es distinto a ${workshopState?.phone_number}, introdúcelo aquí`}>
                        <Stack style={{ width: "90%" }} height={"50px"} alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                          <FormControlLabel value="Llamada" control={<Radio onClick={handleRadioChange} />} label="Llamada" />
                          {selectedValueState === "Llamada" && (
                            <TextField
                              sx={{ marginBottom: "15px", width: "calc(100% - 100px)" }}
                              {...register("cta")}
                              value={ctaValueState}
                              onChange={(e) => setCtaValueState(e.target.value)}
                              helperText={errors.cta?.message}
                              id="contactPhone"
                              label={workshopState?.phone_number}
                              variant="standard"
                              required={false}
                            />
                          )}
                        </Stack>
                      </Tooltip>
                      <Tooltip arrow placement="left" title={`Si el correo es distinto a ${workshopState?.email}, introdúcelo aquí`}>
                        <Stack style={{ width: "90%" }} height={"50px"} sx={{ width: "calc(100% - 100px)" }} alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                          <FormControlLabel value="Email" control={<Radio onClick={handleRadioChange} />} label="Email" />
                          {selectedValueState === "Email" && (
                            <TextField
                              sx={{ marginBottom: "15px", width: "calc(100% - 100px)" }}
                              {...register("cta")}
                              value={ctaValueState}
                              onChange={(e) => setCtaValueState(e.target.value)}
                              helperText={errors.cta?.message}
                              id="contactEmail"
                              label={workshopState?.email}
                              variant="standard"
                              required={false}
                            />
                          )}
                        </Stack>
                      </Tooltip>
                      <Tooltip arrow placement="left" title="Si utilizas software para el manejo de citas previas, intruduce aqui la URL">
                        <Stack style={{ width: "90%" }} height={"50px"} alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                          <FormControlLabel value="Otro" control={<Radio onClick={handleRadioChange} />} label="URL" />
                          {selectedValueState === "Otro" && (
                            <TextField
                              sx={{ marginBottom: "15px", width: "calc(100% - 100px)" }}
                              {...register("cta")}
                              value={ctaValueState}
                              helperText={errors.cta?.message}
                              onChange={(e) => setCtaValueState(e.target.value)}
                              id="URL"
                              label={`URL`}
                              variant="standard"
                              required={false}
                            />
                          )}
                        </Stack>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </RadioGroup>
              </>
            )}
            <Stack sx={{ alignItems: "center", justifyContent: "flex-start" }} gap={"30px"}>
              {workshopState.google_rating && (
                <Stack marginTop={"20px"} sx={{ alignItems: "center", justifyContent: "center", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.15)" }}>
                  <Typography color={"white"} sx={{ backgroundColor: "#909090", width: "100%", padding: "7px 0", textTransform: "uppercase", textAlign: "center", fontWeight: "200", fontSize: "1.5rem" }}>
                    Reputación en Google
                  </Typography>
                  <Stack sx={{ alignItems: "center", justifyContent: "flex-start" }} direction={"row"}>
                    <Stack width={"60%"} gap={"10px"}>
                      <Stack alignItems={"center"} justifyContent={"center"} sx={{ alignItems: "center", justifyContent: "center", gap: "10px", padding: "10px" }} direction={"row"}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={"5px"}>
                          {workshopState.reviews?.length} Opiniones
                        </Stack>
                      </Stack>
                      {workshopState.reviews && (
                        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
                          <Stack position={"relative"} gap={"5px"} maxHeight={"400px"} overflow={"auto"} padding={"10px"} marginBottom={"10px"}>
                            {workshopState.reviews.map((review, index) => (
                              <Stack key={index} sx={{ gap: "5px", borderRadius: "0px", padding: "10px" }}>
                                <Typography sx={{ fontWeight: "600", fontSize: "0.8rem", display: "flex", alignItems: "center" }}>
                                  <span style={{ color: "#e94e1b" }}>{review.rating}</span> <StarIcon sx={{ height: "15px", width: "15px", marginRight: "2px", color: "#e94e1b" }} /> {review.author_name}{" "}
                                  <span style={{ textAlign: "right", flex: 1, fontWeight: 300, fontSize: "0.75rem" }}> {new Date(review.time * 1000).toLocaleDateString()}</span>
                                </Typography>
                                <Typography sx={{ fontWeight: "300", fontSize: "0.81rem", lineHeight: "1.2rem" }}>{review.text}</Typography>
                              </Stack>
                            ))}
                          </Stack>
                          <Button
                            onClick={() => window.open(workshopState.url)}
                            sx={{ padding: "2px", lineHeight: "0.8rem", minWidth: "0px", maxWidth: "100px", border: "1.5px solid #e94e1b", fontSize: "0.7rem", marginBottom: "10px" }}
                          >
                            Ver más
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                    <Stack gap={"50px"} style={{ width: "40%" }} height={"100%"}>
                      <Stack alignItems={"center"} justifyContent={"center"} sx={{ gap: "5px", padding: "10px", backgroundColor: "white" }} direction={"row"}>
                        <Stack direction={"row"} gap={"5px"}>
                          {workshopState?.google_rating?.user_ratings_total} Valoraciones
                        </Stack>
                      </Stack>
                      <Stack height={"calc(100% - 150px)"} alignItems={"center"} justifyContent={"space-evenly"}>
                        <img style={{ width: "100%" }} src={workshopState.googleRatingChart} alt="" onClick={()=>{setBubbleChartIsOpen(!bubbleChartIsOpen)}}/>
                        <img style={{ width: "40%" }} src={"/img/google_my_business.jpg"} alt="" />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {workshopState.doughnutChart && workshopState.satisfactionChart && (
                <Stack sx={{ alignItems: "center", width: "100%", justifyContent: "flex-start", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.15)" }}>
                  <Typography color={"white"} sx={{ backgroundColor: "#909090", width: "100%", padding: "7px 0", textTransform: "uppercase", textAlign: "center", fontWeight: "200", fontSize: "1.5rem" }}>
                    Satisfacción del cliente
                  </Typography>
                  <Typography marginTop={"10px"} color={"grey"} sx={{ textTransform: "uppercase", textAlign: "center", fontWeight: "250" }}>
                     Puntuación enviada por whatsapp
                    </Typography>
                  <img src={workshopState.doughnutChart} alt="Workshop" style={{ width: "100%", height: "auto", margin: "-15px 0", zIndex: "-1", maxWidth: "500px" }} />
                  <Typography  color={"grey"} sx={{ textTransform: "uppercase", textAlign: "center", fontWeight: "250" }}>
                      Nivel de felicidad
                    </Typography>
                  <img style={{ width: "90%", height: "auto", padding: "10px 0", maxWidth: "500px" }} src={workshopState.satisfactionChart} alt="" />
                </Stack>
              )}
            </Stack>
            <IconButton
              sx={{
                position: "fixed",
                bottom: "30px",
                right: "40px",
                height: "50px",
                width: "50px",
                backgroundColor: demo ? "grey" : "primary.main",
                color: "white",
                borderRadius: "100%",
                boxShadow: "0 0 10px 2px rgba(0, 0, 0, 0.2)",
              }}
              variant="contained"
              type="submit"
              disabled={demo ? true : false}
            >
              {loadingSaveState ? <CircularProgress sx={{ color: "white", height: "10px", width: "10px" }} /> : <SaveIcon sx={{ color: demo ? "grey" : "white", height: "20px", width: "20px" }} />}
            </IconButton>
            {bubbleChartIsOpen && <BubbleChart reviews={workshopState.reviews} />}
          </form>
          
        )}
      </Stack>
    </MainContent>
  );
};

export default WorkshopPage;