import React from "react";
import ModalComponent from "../../../components/Modal.Component.jsx";
import { Stack, Typography, Button } from "@mui/material";
import { getStorageObject } from "../../../utils/LocalStorage.js";

const ConfirmEditModal = ({ interventionData, editModal, setEditModal, setInterventionEditMode }) => {

  const handleEditIntervention = () => {

    setInterventionEditMode(true);

    const token = getStorageObject("token");
    const InterventionURL = `/intervencion?id1=${interventionData.user_repairshop_id}&id2=${interventionData.id}&id4=${token}`;

    window.open(InterventionURL, '_blank');
  };

  return (
    <ModalComponent>
      <Stack
        sx={{
          height: "fit-content",
          maxHeight: "90%",
          width: "fit-content",
          padding: "20px",
          paddingTop: "40px",
          backgroundColor: "white",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack alignItems="center" justifyContent="center" width="fit-content" gap="10px" padding="0px">
          <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>¿Deseas continuar con la intervención?</Typography>
          <Button sx={{ width: "50%" }} onClick={handleEditIntervention} variant="contained">
            Sí
          </Button>
          <Button sx={{ width: "50%" }} onClick={() => setEditModal(false)}>
            Volver
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  );
};

export default ConfirmEditModal;
