import React from 'react'
import ChangePasswordComponent from '../leads/Components/ChangePassword.Component'
import { Stack, Typography } from '@mui/material'
import LoadingComponent from '../../components/Loading.Component'
import { useState } from 'react'
import { useEffect } from 'react'
import { validateRecoveryLink } from '../../services/API/User.Routes'



const RecoverAccountPage = () => {

  const [loadingState, setLoadingState] = useState(true)
  const [successState, setSuccessState] = useState(false)
  const [tokenState, setTokenState] = useState()
  const [idState, setIdState] = useState()


  useEffect(() => {
    const queryString = window.location.search;
    const recoveryParams = queryString.replace(/^\?/, "");
    const params = new URLSearchParams(recoveryParams);

    const idState = params.get('id');
    const tokenState = params.get('token');

    if (tokenState && idState) {
      validateRecoveryLink(tokenState, idState).then(({ data: recoveryLinkData, status, ok }) => {
        if (recoveryLinkData.type === "success") {
          setTokenState(tokenState)
          setIdState(idState)
          setSuccessState(true)
          setLoadingState(false)
        }
      }).catch(error => { });
    } else {
      setSuccessState(false)
      setLoadingState(false)
    }
  }, [])

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <Stack sx={{ height: "calc(100vh - 30px)", width: "100%", justifyContent: "center", alignItems: "center" }}>

      {successState ? <ChangePasswordComponent id={idState} token={tokenState} /> :

        <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
            <img style={{ height: "35px" }} src="/img/drivi_logo.svg" alt="" />
            <Typography fontWeight={"300"} variant="subtitle1">
              El link ha expirado. Por favor, vuelve a intentarlo.
            </Typography>
          </Stack>
        </Stack>

      }
    </Stack>
  );
}

export default RecoverAccountPage